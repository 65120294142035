import React from "react";
import noMessage from "../../assets/no-message.png";
import ChatArea from "./ChatArea";
import ConvoList from "./convoList";
import Details from "./details";
import { useInox } from "../../context/InboxContext";

const Inbox = () => {
  const { history, conversationId, setConversationId, chats, setChats, message, setMessage, selectedOption, setSelectedOption, newMessages, setNewMessages, userList, botList, openDetails, setOpenDetails, saveChat, switchManual } = useInox();

  return (
    <div className="flex h-full bg-[#F3F2F7]">
      <ConvoList history={history} conversationId={conversationId} setConversationId={setConversationId} setChats={setChats} selectedOption={selectedOption} setSelectedOption={setSelectedOption} newMessages={newMessages} setNewMessages={setNewMessages} userList={userList} botList={botList} />

      {history.length === 0 && <div className="w-full flex flex-col justify-center items-center gap-4">
        <img src={noMessage} alt="" />
        <h2 className="text-gray/950 text-base font-semibold">You do not have any conversation yet!</h2>
      </div>}
      {conversationId && <ChatArea openDetails={openDetails} setOpenDetails={setOpenDetails} chats={chats} saveChat={saveChat} message={message} setMessage={setMessage} switchManual={switchManual} />}

      {openDetails && conversationId && <Details setOpenDetails={setOpenDetails} chats={chats} />}
    </div>
  );
};

export default Inbox;
