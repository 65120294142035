import React, { useState } from "react";
import defaultTheme from "../../../../assets/dafault-theme.png";
import audioTheme from "../../../../assets/audio-theme.png";
import dayoneTheme from "../../../../assets/dayone-theme.png";
import botstreamsTheme from "../../../../assets/botstreams-theme.png";
import instance from "../../../../api";
import { useParams } from "react-router-dom";
import { notification } from "../../../../helper";

const Themes = ({ code, setCode, setLoading, getBot }) => {
  const [selectedTheme, setSelectedTheme] = useState(JSON.parse(code).theme);
  const { botId } = useParams();

  const themes = [
    {
      key: "simple",
      name: "Simple",
      image: defaultTheme,
      description:
        "This is the bot's basic default theme.",
    },
    {
      key: "audio",
      name: "Minimalist",
      image: audioTheme,
      description:
        "Clean white background with minimal color for a simple chat experience.",
    },
    {
      key: "dayone",
      name: "Vibrant Gradient",
      image: dayoneTheme,
      description:
        "Bold gradient backgrounds and bright colors for a lively chat experience.",
    },
    {
      key: "botstreams",
      name: "Modern style",
      image: botstreamsTheme,
      description:
        "The look and feel are clean, user-friendly, and intuitive, featuring a minimalist design.",
    },
  ];

  const handleTheme = (key) => {
    setSelectedTheme(key);
    const codeObject = JSON.parse(code);
    codeObject.theme = key;
    updateBot(codeObject);
  }

  const updateBot = async (data) => {
    setLoading(true);
    try {
      const response = await instance.put(`/bot/update/${botId}`, { ...data });
      console.log(response);
      setLoading(false);
      getBot();
      notification.success("Theme changed successfully!");
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error(err?.response?.data?.message?.error);
    }
  };

  return (
    <div className="m-5">
      <h1 className="text-xl font-semibold text-gray/950">
        Themes
      </h1>
      <div className="mt-6 flex items-start gap-6 flex-wrap">
        {themes.map((item) => (
          <div key={item.key} className={`w-60 ${selectedTheme === item.key ? "border-2 border-blue/500 rounded-lg" : ""}`}>
            <img src={item.image} alt="" className="w-full" />
            <div className="bg-white p-4 rounded-b-lg space-y-1 h-44 flex flex-col justify-between">
              <div>
                <h2 className="text-base text-gray/950 font-semibold">
                  {item.name}
                </h2>
                <p className="text-gray/500 text-sm">{item.description}</p>
              </div>
              <div className="">
                {selectedTheme === item.key ? <></> : <button className="btn-2" onClick={() => handleTheme(item.key)}>Enable</button>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Themes;
