import React, { useEffect, useState } from "react";
import instance from "../../api";
import { notification } from "../../helper";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../context/SubscriptionContext";
import tick from "../../assets/tick-2.png";
import current from "../../assets/current.png";
import rocket from "../../assets/rocket.png";
import cross from "../../assets/cross.png";
import soon from "../../assets/Coming Soon.png";
import active from "../../assets/trial active.png";
import { FaArrowRightLong, FaBolt, FaX } from "react-icons/fa6";
import { Modal } from "antd";

const Pricing = ({ user, setLoading }) => {
  const [plans, setPlans] = useState([]);
  const { orgDetails, getOrgById, currentOrg } = useSubscription();
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [activateTrialModal, setActivateTrialModal] = useState(false);
  const [activatedTrialModal, setActivatedTrialModal] = useState(false);

  useEffect(() => {
    getAllPlans();
  }, []);

  const getAllPlans = (id) => {
    instance
      .get(`/razorpay/plans`)
      .then((res) => {
        console.log(res, "This is all plans");
        setPlans(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const cancelSub = (id) => {
    instance
      .post(`/razorpay/subscribe/cancel`, {
        sub_id: orgDetails.razorpay.razorpay_subscription_id,
      })
      .then((res) => {
        notification.success("Subscription cancelled successfully");
        console.log(res, "This is cancelSub");
        navigate("/");
        getOrgById(orgDetails.org_id);
        // setPlans(res.data.data.items);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const handlePayment = async (plan) => {
    if(plan.details.name === "Free"){
      await instance.post(`/razorpay/subscribe`, {plan: plan.details, org_id: orgDetails.org_id})
      .then((res) => {
        console.log(res, "This is free plan");
        notification.success("Free Trial activated");
        setActivatedTrialModal(true);
        getOrgById(orgDetails.org_id);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
      return;
    }
    try {
      const subscriptionId = await instance.post(`/razorpay/subscribe`, {
        plan_id: plan.id,
      });
      console.log(subscriptionId.data.data.id, "This is subscriptionId");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        subscription_id: subscriptionId.data.data.id,
        name: plan.item.name,
        description: plan.item.description,
        // image: '',
        callback_url: `${process.env.REACT_APP_BASE_URL}/razorpay/callback/${currentOrg}`,
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.contact,
        },
        theme: {
          color: "#7F51F2",
        },
      };
  
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  const updateSub = async (plan) => {
    try {
      await instance.post(`/razorpay/subscribe/update`, {
        sub_id: orgDetails.razorpay.razorpay_subscription_id,
        plan_id: plan.id,
      });
      notification.success("Subscription updated successfully");
      navigate("/");
      getOrgById(orgDetails.org_id);
      // console.log(subscriptionId, "This is update subscriptionId");
      // const options = {
      //   key: 'rzp_test_jv07wAeuKzpide',
      //   subscription_id: subscriptionId.data.data.id,
      //   name: plan.item.name,
      //   description: plan.item.description,
      //   // image: '',
      //   callback_url: `http://localhost:8000/razorpay/callback/${currentOrg}`,
      //   prefill: {
      //     name: user.name,
      //     email: user.email,
      //     contact: user.contact,
      //   },
      //   theme: {
      //     color: "#7F51F2",
      //   },
      // };
  
      // const rzp1 = new window.Razorpay(options);
      // rzp1.open();
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  const features = [
    "RAG Based Chatbot",
    "OpenAI Models",
    "LLAMA 3 Models",
    "Fine tuned or custom models",
    "Team Members",
    "Add knowledge from files",
    "Add knowledge from confluence",
    "Total File Size Knowledge Bases",
    "Integration With Teams",
    "Integration With Whatsapp",
    "Number of AI generated words",
    "Team Inbox",
    "Custom Theme",
    "Shopify Integration",
    "Custom API calling",
    "Add Knowledge from Webpages",
    "AI / Manual Agent chat switching",
    "Number of bots",
    "Free Themes",
    "Logo change"
  ];

  return (
    <div className="h-[calc(100vh-10rem)]">
      {showWarning && <div className="bg-[#FFB524] p-2 px-6 flex justify-between items-center">
        <div></div>
        <h2 className="text-gray/950 font-medium">The amounts and packages shown are here for demonstration only.</h2>
        <button onClick={() => setShowWarning(false)}>
          <FaX className="text-gray/950 text-xs" />
        </button>
      </div>}

      <div className="px-20 space-y-10 py-5">
        <h1 className="text-center grotesk text-gray/900 text-3xl font-medium">Choose your plans</h1>
        {orgDetails?.free_trial === "pending" && !orgDetails?.razorpay &&
        <div className="bg-white p-4 rounded-md flex justify-between items-center">
          <div className="flex items-center gap-2 text-gray/950 text-base font-medium">
            <img src={rocket} alt="" />
            Ready to dive in? Activate your 14-days free trial and unlock the full experience-no strings, just pure app awesomeness!
          </div>
          <button className="btn flex items-center gap-2 text-sm py-3" onClick={() => setActivateTrialModal(true)}>
            <FaBolt className="text-base" />
            Activate Free Trial
          </button>
        </div>}
        <div className="grid grid-cols-6 gap-6 text-gray/950">
          <div className="col-span-2 text-base font-medium mt-[116px]">
            <h2 className="font-bold">Features</h2>
            <div className="divide-y border-t mt-8">
              {features.map((item, i) => (
                <div key={i} className="h-14 flex items-center">
                  {item}
                </div>
              ))}
            </div>
          </div>
          {plans.filter(plan => plan.details.name !== "Free").map((plan, i) => {
            const feature = plan?.details?.features[0];
            return(
              <div key={i} className={`bg-white rounded-xl p-4`}>
                <div className="text-center mt-2 space-y-3">
                  <h2 className="text-xl font-semibold">{plan.details.name}</h2>
                  <h1 className="text-4xl font-semibold mt-2">
                    {plan.details.name === "Custom" 
                      ? <span className="text-base">Custom Pricing</span>
                      : <>
                          ${new Intl.NumberFormat('en-IN').format(plan.details.amount)}
                          <span className="text-base">/month</span>
                        </>
                    }
                  </h1>
                  {plan.details.name === "Custom"
                    ? <button className="btn w-full text-base" onClick={() => window.location.href = 'mailto:umesh@spirinova.dev'}>Contact Sales</button>
                    : <>
                        {orgDetails?.status !== "active" && <button className="btn w-full text-base" onClick={() => handlePayment(plan)}>
                          Subscribe
                        </button>}
                        {orgDetails?.plan_id !== plan.id && orgDetails?.status === "active" && <button className="btn w-full text-base" onClick={() => {setSelectedPlan(plan); setUpgradeModal(true)}}>
                          Upgrade
                        </button>}
                        {(orgDetails?.plan_id === plan.id && orgDetails?.status === "active") && <button className="btn w-full text-base bg-gray/100 text-gray/950">
                          Current Plan
                        </button>}
                      </>
                  }
                </div>
                <div className="divide-y border-t mt-5 text-base font-medium">
                  <div className="h-14 flex justify-center items-center">
                    {feature?.ragBasedChatbot ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.openAIModels ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.llama3Models ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.fineTunedOrCustomModels ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.teamMembers}
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.addKnowledgeFromFiles ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.addKnowledgeFromConfluence ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.totalFileSizeKnowledgeBases }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.integrationWithTeams === 'Coming soon' ? 'Coming soon!' : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.integrationWithWhatsapp ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.numberOfAIGeneratedWords }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.teamInbox ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.customTheme ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.shopifyIntegration === 'Coming soon' ? 'Coming soon!' : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.customApiCalling ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.addKnowledgeFromWebpages }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.aiManualAgentChatSwitching ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.numberOfBots }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.freeThemes ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                  <div className="h-14 flex justify-center items-center">
                    {feature?.logoChange ? <img src={tick} alt="" /> : <img src={cross} alt="" /> }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <Modal
        title='Confirm your plan change'
        open={upgradeModal}
        footer={null}
        centered
        onCancel={() => setUpgradeModal(false)}
      >
        <div>
          <div className="flex justify-between items-center bg-gray/100 border border-gray/200 p-2 rounded-md">
            <div className="text-gray/500">
              <h2>Current Plan</h2>
              <h1 className="font-semibold">{orgDetails?.plan_name} ${orgDetails?.plan_amount / 100}/month</h1>
            </div>
            <FaArrowRightLong className="text-lg" />
            <div className="text-gray/950">
              <h2>New Plan</h2>
              <h1 className="font-semibold">{selectedPlan?.details?.name} ${selectedPlan?.details?.amount}/month</h1>
            </div>
          </div>
          <p className="text-gray/950 mt-4">
            Your new plan starts now. The remaining amount will be {orgDetails?.plan_amount / 100 - selectedPlan?.details?.amount < 0 ? 'charged now' : 'refunded to your account'}, and your billing cycle will continue from {formatDate(orgDetails?.next_charge_at)}.
          </p>
          <div className="mt-6 flex justify-end items-center gap-6">
            <button className="text-gray/950 font-medium" onClick={() => setUpgradeModal(false)}>
              Cancel
            </button>
            <button className="btn" onClick={() => updateSub(selectedPlan)}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title='Activate Free Trial'
        open={activateTrialModal}
        footer={null}
        centered
        onCancel={() => setActivateTrialModal(false)}
      >
        <div>
          <p className="text-gray/950 mt-4">
            You’re all set! Enjoy full access to all the Basic plan features for free over the next 14 days. After that, you'll need to choose a plan to keep the good times rolling. Start exploring now!
          </p>
          <div className="mt-6 flex justify-end items-center gap-6">
            <button className="text-gray/950 font-medium" onClick={() => setActivateTrialModal(false)}>
              Cancel
            </button>
            <button className="btn" onClick={() => {handlePayment(plans[0]); setActivateTrialModal(false)}}>
              Try it free
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title={null}
        open={activatedTrialModal}
        footer={null}
        centered
        onCancel={() => setActivatedTrialModal(false)}
        closable={false}
      >
        <div>
          <img className="-mt-4" src={active} alt="" />
          <p className="text-gray/950 mt-6 text-xl font-semibold text-center">
            Free Trial Activated!
          </p>
          <div className="mt-6 flex justify-center items-center gap-6">
            <button className="btn" onClick={() => navigate('/')}>
              Back to Home
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Pricing;
