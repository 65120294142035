import React, { useState, useEffect } from "react";
import { BsPersonFill } from "react-icons/bs";
import { FaBolt, FaRegCreditCard } from "react-icons/fa6";
import instance from "../../api";
import { convertFromBytes, convertToBytes, notification, returnPlanName } from "../../helper";
import { useNavigate } from "react-router-dom";
import { Form, Input, Modal, Progress, Table } from "antd";
import useAccessToken from "../../hooks/useAccessToken";
import { useSubscription } from "../../context/SubscriptionContext";
import { IoWarningOutline } from "react-icons/io5";
import tick from "../../assets/tick.png";
import { LuDownload } from "react-icons/lu";

const Profile = ({ user, getUser }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [invoices, setInvoices] = useState([]);
  const [data, setData] = useState({
    name: user?.name,
  });
  const navigate = useNavigate();
  form.setFieldsValue({
    name: user?.name,
  });
  const { orgDetails, members, setMembers, allBots, setAllBots, currentOrg, getOrgById } = useSubscription();
  const [cancelModal, setCancelModal] = useState(false);

  const fetchAccessToken = useAccessToken();
  
  useEffect(() => {
    getMembers();
    if(currentOrg) getBots();
    if(orgDetails?.razorpay?.razorpay_subscription_id) {
      getInvoices();
    }
  }, [currentOrg]);

  const submit = async (data) => {
    const token = await fetchAccessToken(); 
    instance
      .post(`/auth/update-name`, {
        userId: user.sub,
        name: data.name
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        notification.success("Name updated successfully!");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const getMembers = async () => {
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/org-members", { org_id: currentOrg }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is members");
        setMembers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const getBots = async () => {
    const token = await fetchAccessToken();
    instance.get(`/bot/org/${currentOrg}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(async (res) => {
        // console.log(res.data.data, "This is all bots");
        if (res.data.data.length === 0) {
          setAllBots([]);
        } else {
          setAllBots(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const getInvoices = async () => {
    instance.post(`/razorpay/invoices/${orgDetails?.razorpay?.razorpay_subscription_id}`)
      .then(async (res) => {
        console.log(res.data.data, "This is all invoices");
        setInvoices(res.data.data.items);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  const dataSource = invoices?.map((invoice, index) => ({
    key: index.toString(),
    status: <h2 className="capitalize text-base text-gray/950 flex items-center gap-1">
      {invoice.status === 'paid' && <img src={tick} alt="tick" />}
      {invoice.status}
    </h2>,
    date: <h2 className="text-gray/950 text-base">{formatDate(invoice.issued_at)}</h2>,
    amount: <h2 className="text-gray/950 text-base">${invoice.amount / 100}</h2>,
    transaction: <h2 className="text-gray/950 text-base">{invoice.id}</h2>,
    download: <a href={invoice.short_url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 text-blue/500 text-base font-medium">
      <LuDownload className="text-lg" />
      <h2 className="">Download</h2>
    </a>,
  }));
  
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Issue date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transaction',
      key: 'transaction',
    },
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
    },
  ];

  const cancelSub = () => {
    instance
      .post(`/razorpay/subscribe/cancel`, {
        sub_id: orgDetails.razorpay.razorpay_subscription_id,
      })
      .then((res) => {
        notification.success("Subscription cancelled successfully");
        console.log(res, "This is cancelSub");
        navigate("/");
        getOrgById(orgDetails.org_id);
        // setPlans(res.data.data.items);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  return (
    <div className="m-5 space-y-5">
      <div className="flex items-center gap-5">
        <button
          className={`font-semibold flex items-center gap-2 transition-all ${
            step === 1 ? "btn" : "text-gray/400 p-2 px-4"
          }`}
          onClick={() => setStep(1)}
        >
          <BsPersonFill className="text-lg" />
          Account
        </button>
        <button
          className={`font-semibold flex items-center gap-2 transition-all ${
            step === 2 ? "btn" : "text-gray/400 p-2 px-4"
          }`}
          onClick={() => setStep(2)}
        >
          <FaRegCreditCard className="text-lg" />
          Billing & Plans
        </button>
      </div>

      {step === 1 && (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={(val) => submit(val)}
          className="bg-white p-5 rounded-lg shadow-md"
        >
          <h2 className="label">Profile Details</h2>
          <div className="mt-3">
            <img
              className="size-24 rounded-md"
              src={user?.picture ? user?.picture : `https://ui-avatars.com/api/?name=${user?.name}&bold=true&color=fff&background=cdbbfa`}
              alt=""
            />
          </div>
          <div className="border-b border-gray/200 py-3"></div>
          <div className="mt-4 grid grid-cols-3 gap-5">
            <Form.Item
              label={<h2 className="label">Name</h2>}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                },
              ]}
            >
              <Input
                className="input min-w-full"
                placeholder="Enter your name"
              />
            </Form.Item>
            <div className="space-y-2">
              <h2 className="label">Organization</h2>
              <input
                type="text"
                disabled
                className="input min-w-full"
                value={orgDetails?.name}
              />
            </div>
            {/* <div className="space-y-2">
              <h2 className="label">Domain</h2>
              <input
                type="text"
                disabled
                className="input min-w-full"
                value={`${process.env.REACT_APP_CHAT_FE_URL}/#/chat/${orgDetails?.domain}`}
                placeholder="Enter Your Domain"
              />
            </div> */}
            <div className="space-y-2">
              <h2 className="label">Email</h2>
              <input
                type="text"
                disabled
                className="input min-w-full"
                value={user?.email}
                placeholder="Enter Your Email"
              />
            </div>
          </div>
          <button className="btn mt-8">Save Changes</button>
        </Form>
      )}

      {step === 2 && (
        <div>
          <h2 className="grotesk text-xl font-semibold mb-3">Current Plan</h2>
          <div className="bg-white p-5 rounded-lg shadow-md">

            {orgDetails?.free_trial === "active" && <div className="bg-blue/50 px-5 py-3 rounded-lg mt-5 flex items-center justify-between">
              <div className="text-blue/500 font-medium flex items-center gap-1">
                <IoWarningOutline className="text-xl" />
                <h2>You are currently using free trial. Upgrade to create more and unlock premium features</h2>
              </div>
              <button className="btn py-2 flex items-center gap-2" onClick={() => navigate("/pricing")}>
                <FaBolt className="text-lg" />
                Upgrade now
              </button>
            </div>}

            {orgDetails?.status === "active" && <div className="bg-gray/100 px-5 py-3 rounded-lg mt-5 flex items-center justify-between">
              <div className="">
                <h2 className="text-gray/950 text-base font-medium flex items-center gap-1">Current Plan: <span className="font-bold">{orgDetails?.plan_name} ${orgDetails?.plan_amount / 100}/month</span></h2>
                <h2 className="text-gray/600 text-sm">Next payment- {formatDate(orgDetails?.next_charge_at)}</h2>
              </div>
              <div className="flex gap-4">
                <button className="btn py-2 flex items-center gap-2 bg-white text-error/500" onClick={() => setCancelModal(true)}>
                  Cancel Plan
                </button>
                <button className="btn py-2 flex items-center gap-2" onClick={() => navigate("/pricing")}>
                  Change Plan
                </button>
              </div>
            </div>}

            {orgDetails?.free_trial !== "active" && orgDetails?.status !== "active" && <div className="bg-gray/100 px-5 py-3 rounded-lg mt-5 flex items-center justify-between">
              <div className="text-gray/950 font-medium flex items-center gap-1">
                <h2>You have no active plan</h2>
              </div>
              <button className="btn py-2 flex items-center gap-2" onClick={() => navigate("/pricing")}>
                Change Plan
              </button>
            </div>}

            {(orgDetails?.free_trial === "active" || orgDetails?.status === "active") && <div className="flex items-start gap-5 mt-5">
              {/* <div className="w-1/2 p-5 border border-gray/200 rounded-lg">
                <h1 className="text-lg font-semibold text-gray/950">Included Features</h1>
                <div className="">
                  {orgDetails?.features?.map((feature) => (
                      <p key={feature} className="flex items-center gap-2 text-base mt-2">
                        <img src={tick} alt="tick" className="mt-0.5" />
                        {feature}
                      </p>
                  ))}
                </div>
              </div> */}
              <div className="w-full p-5 border border-gray/200 rounded-lg">
                <h1 className="text-lg font-semibold text-gray/950">Usage</h1>
                <div className="mt-5">
                  <h2 className="text-base">Teams</h2>
                  <div className="flex items-center gap-3">
                    <Progress
                      type="line"
                      percent={((members?.length - 1)  / orgDetails?.team) * 100}
                      trailColor="#D6D8E1"
                      strokeColor="#7F51F2"
                      strokeWidth={8}
                      showInfo={false}
                    />
                    <h3 className="shrink-0 text-sm text-gray/950">{members?.length - 1} of {orgDetails?.team} used</h3>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="text-base">Bots</h2>
                  <div className="flex items-center gap-3">
                    <Progress
                      type="line"
                      percent={((allBots?.length)  / orgDetails?.bot) * 100}
                      trailColor="#D6D8E1"
                      strokeColor="#7F51F2"
                      strokeWidth={8}
                      showInfo={false}
                    />
                    <h3 className="shrink-0 text-sm text-gray/950">{allBots?.length} of {orgDetails?.bot} used</h3>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="text-base">Tokens</h2>
                  <div className="flex items-center gap-3">
                    <Progress
                      type="line"
                      percent={((orgDetails?.used_token)  / orgDetails?.token) * 100}
                      trailColor="#D6D8E1"
                      strokeColor="#7F51F2"
                      strokeWidth={8}
                      showInfo={false}
                    />
                    <h3 className="shrink-0 text-sm text-gray/950">{orgDetails?.used_token} of {orgDetails?.token} used</h3>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="text-base">File Storage Used</h2>
                  <div className="flex items-center gap-3">
                    <Progress
                      type="line"
                      percent={((orgDetails?.used_file_size)  / convertToBytes(orgDetails?.file_upload_limit)) * 100}
                      trailColor="#D6D8E1"
                      strokeColor="#7F51F2"
                      strokeWidth={8}
                      showInfo={false}
                    />
                    <h3 className="shrink-0 text-sm text-gray/950">{convertFromBytes(orgDetails?.used_file_size)} of {orgDetails?.file_upload_limit} used</h3>
                  </div>
                </div>
              </div>
            </div>}
          </div>

          {dataSource.length > 0 && <h2 className="grotesk text-xl font-semibold mt-8 mb-3">Billing History</h2>}
          {orgDetails?.razorpay?.razorpay_subscription_id && <div className="bg-white p-5 rounded-lg shadow-md">
            <Table columns={columns} dataSource={dataSource} />
          </div>}
        </div>
      )}

      <Modal
        title={`Cancel ${orgDetails?.plan_name} Plan`}
        open={cancelModal}
        footer={null}
        centered
        onCancel={() => setCancelModal(false)}
      >
        <div>
          <p className="text-gray/950">
            Want to cancel your subscription? <br />
            You will still have access to  Botstreams AI until the end of your current billing period, on {formatDate(orgDetails?.next_charge_at)}.
          </p>
          <div className="mt-6 flex justify-end items-center gap-6">
            <button className="text-gray/950 font-medium" onClick={() => setCancelModal(false)}>
              Keep My Subscription
            </button>
            <button className="btn bg-error/500" onClick={cancelSub}>
              Cancel Subscription
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
