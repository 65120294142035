import { useHandleSignInCallback, useLogto } from "@logto/react";
import { useNavigate } from "react-router-dom";

const Callback = ({ setLoading }) => {
  const { fetchUserInfo, getAccessToken } = useLogto();
  const navigate = useNavigate();

  const { isLoading } = useHandleSignInCallback(async () => {
    const accessToken = await getAccessToken(process.env.REACT_APP_BASE_URL);
    // setToken(accessToken)
    // localStorage.setItem('token', accessToken);

    const user = await fetchUserInfo();
    // setUser(user);
    
    const redirectUri = sessionStorage.getItem('redirectAfterLogin')

    if(redirectUri) {
      setLoading(false);
      sessionStorage.removeItem('redirectAfterLogin');
      navigate(redirectUri)
    }
    else if(user.organizations.length === 0){
      setLoading(false);
      navigate('/form')
    } 
    else {
      setLoading(false);
      navigate('/')
    }
  });

  // if (isLoading) {
  //   setLoading(true);
  // }

  return null;
};

export default Callback;
