import React, { useEffect, useState } from "react";
import { Modal, Select, Upload } from "antd";
import { FaAngleDown, FaBolt } from "react-icons/fa6";
import { botService } from "../../../../services/botService";
import { HiOutlineTrash } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../../api";
import { BoxLoader, notification } from "../../../../helper";
import IconPicker from 'react-icons-picker';
import { GoTrash, GoPencil } from "react-icons/go";
import { useSubscription } from "../../../../context/SubscriptionContext";
import ImgCrop from "antd-img-crop";
const { Option } = Select;

const Setup = ({ user, code, setCode, getBot, setKey }) => {
  const [botModels, setBotModels] = useState([]);
  const [parsedCode, setParsedCode] = useState(JSON.parse(code));
  const [initialModel, setInitialModel] = useState(parsedCode?.model_config?.model);
  const navigate = useNavigate();
  const { botId } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState("FaUsers");
  const { canCreateBot, currentOrg, orgDetails } = useSubscription();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [avatar, setAvatar] = useState([]);
  const [signedImg, setSignedImg] = useState();

  useEffect(() => {
    getBotModels();
  }, []);

  useEffect(() => {
    const parsed = JSON.parse(code);
    setParsedCode(parsed);
    setInitialModel(parsed?.model_config?.model); // Track initial model value
  }, [code]);

  useEffect(() => {
    if (parsedCode?.avatar) {
      // Check if parsedCode.avatar is a valid URL
      const isValidUrl = (string) => {
        try {
          new URL(string);
          return true;
        } catch (_) {
          return false;
        }
      };

      if (isValidUrl(parsedCode.avatar)) {
        // If it's a valid URL, set signedImg directly
        setSignedImg(parsedCode.avatar);
      } else {
        // If it's a path, call getSignedURL
        getSignedURL(parsedCode.avatar).then((url) => {
          if (url) {
            setSignedImg(url); // Set the signed image URL
          }
        });
      }
    }
  }, [parsedCode]);

  useEffect(() => {
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach((element) => {
      // Set initial height based on preloaded data
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;

      // Add event listener to adjust height on input
      const adjustHeight = (event) => {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      };

      element.addEventListener("input", adjustHeight);

      // Cleanup event listeners on component unmount
      return () => {
        element.removeEventListener("input", adjustHeight);
      };
    });
  }, [parsedCode]);

  const getBotModels = async () => {
    const response = await botService.getBotModels();
    setBotModels(response.models);
  };

  const createBot = async () => {
    if (!canCreateBot()) return;
    setLoading(true);
    let uploadedLogo
    if(avatar.length > 0) {
      uploadedLogo = await uploadAvatar();
    }
    await instance
      .post("/bot/create", {
        ...parsedCode,
        org_id: currentOrg,
        created_by: user?.email,
        ...(uploadedLogo ? { avatar : uploadedLogo } : {}),
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot created successfully!");
        navigate(`/bot/edit/${res.data.data._id}`);
        setKey('data');
      })
      .catch((err) => {
        console.log(err, "err");
        notification.error(err?.response?.data?.message?.error);
        setLoading(false);
      });
  };

  const deleteBot = () => {
    setLoading(true);
    instance
      .delete(`/bot/delete/${botId}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot deleted successfully!");
        navigate('/');
        setDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
        setDeleteModal(false);
        setLoading(false);
      });
  };

  const updateBot = async (callback) => {
    if (callback) setLoading(true);
    let uploadedLogo
    if(avatar.length > 0) {
      uploadedLogo = await uploadAvatar();
    }
    await instance
      .put(`/bot/update/${botId}`, {
        ...parsedCode,
        ...(uploadedLogo ? { avatar : uploadedLogo } : {}),
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        getBot();
        notification.success("Bot updated successfully!");
        if (callback) callback(); // Call the callback function if provided
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const handleGenerate = () => {
    setLoading(true);
    setCurrent(1);
    instance
      .post(
        `/api/generate`,
        { botId: botId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot generated successfully!");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const handleSave = () => {
    const currentModel = parsedCode?.model_config?.model;
    if (currentModel !== initialModel) {
      setCurrent(0);
      setLoading(true);
      updateBot(handleGenerate);
    } else {
      updateBot();
    }
  };

  const onChange = (file) => {
    const { fileList } = file;
    console.log(file);
    const newFileList = fileList;
    console.log(newFileList);
    setAvatar([file.file]);
  };

  const uploadAvatar = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", avatar[0].originFileObj); // Assuming avatar[0] is the file you want to upload
  
    try {
      const response = await instance.post(`/bot/upload-avatar/${currentOrg}`, formData);
      setLoading(false);
      return response.data; // Ensure this returns the expected data
    } catch (error) {
      console.error("Error uploading avatar:", error);
      setLoading(false);
      return null; // Return null or handle the error appropriately
    }
  };

  const getSignedURL = async (path) => {
    try {
      const response = await instance.post('/bot/get-signed-url', {
        path: path
      });
      setSignedImg(response.data.url); // Ensure this returns the expected URL
    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return null; // Return null or handle the error appropriately
    }
  };

  return (
    <div className="m-8">
      <div className="bot-box bg-transparent p-0 grotesk"><h1>Your Bot Settings</h1></div>
      <div className="bot-box space-y-4 mt-4">
        <div className="space-y-2">
          <h2>Name</h2>
          <div className="flex items-center gap-4">
            <div className="relative shrink-0">
              <ImgCrop 
                cropShape='round' 
                zoomSlider 
                aspect={1}
                modalTitle='Adjust your avatar'
                modalOk="Save logo"
              >
                <Upload
                  beforeUpload={() => null}
                  fileList={avatar}
                  onChange={onChange}
                  maxCount={1}
                  showUploadList={false}
                  accept="image"
                  type="button"
                >
                  {avatar.length === 0 ? <img className="size-11 rounded-full shrink-0 mt-1" src={signedImg ? signedImg : 'https://storage.googleapis.com/botstreams-bucket/public/avatar.jpg'} alt="" /> :
                  <img className="size-11 rounded-full shrink-0 mt-1" src={avatar.length > 0 && URL.createObjectURL(avatar[0].originFileObj)} alt="" />}
                  <button className="bg-gray/950 text-white size-5 rounded-full flex justify-center items-center absolute bottom-1 -right-1 border border-white">
                    <GoPencil className="text-xs" />
                  </button>
                </Upload>
              </ImgCrop>
            </div>
            <input
              type="text"
              className="input w-full"
              placeholder="Enter your bot name"
              value={parsedCode?.name || ""}
              onChange={(e) =>
                setParsedCode({ ...parsedCode, name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="space-y-2">
          <h2>Choose your model</h2>
          <div className="input py-1 px-1 w-full">
            <Select
              variant="borderless"
              className="w-full"
              placeholder="Select a model"
              value={parsedCode?.model_config?.model}
              onChange={(e) =>
                setParsedCode({
                  ...parsedCode,
                  model_config: { ...parsedCode.model_config, model: e },
                })
              }
              suffixIcon={<FaAngleDown className="text-gray/950" />}
              options={
                botModels?.map(model => ({
                  value: model?.name,
                  label: (
                    <div className="flex justify-between items-center">
                      <span>{model?.name}</span>
                      {model.provider === 'Ollama' && !orgDetails?.llama_model && (
                        <span className="flex items-center gap-1">
                          <FaBolt />
                          Upgrade
                        </span>
                      )}
                    </div>
                  ),
                  disabled: model.provider === 'Ollama' ? !orgDetails?.llama_model : false
                })) || []
              }
            >
            </Select>
          </div>
        </div>
        <div className="space-y-2">
          <h2>Instructions (optional)</h2>
          <div className="flex justify-start items-start gap-4">
            <textarea
              rows={3}
              className="resize-none input w-full max-h-60"
              placeholder="Enter your instructions"
              value={parsedCode?.instructions[0]?.content || ""}
              onChange={(e) => {
                const updatedInstructions = [...parsedCode?.instructions];
                updatedInstructions[0] = { ...updatedInstructions[0], content: e.target.value };
                setParsedCode({ ...parsedCode, instructions: updatedInstructions });
              }}
            />
          </div>
        </div>
      </div>
      <div className="bot-box bg-transparent p-0 grotesk mt-8"><h1>Pre-defined questions</h1></div>
        <div className="bot-box space-y-4 mt-4">
          {parsedCode?.pre_defined_questions?.map((item, index) => (
            <div key={index} className="space-y-2">
              <h2>Question {index + 1}</h2>
              <div className="flex items-center gap-4">
                <IconPicker value={item.icon} onChange={(v) => {
                  const updatedQuestions = [...parsedCode?.pre_defined_questions];
                  updatedQuestions[index] = { ...updatedQuestions[index], icon: v };
                  setParsedCode({ ...parsedCode, pre_defined_questions: updatedQuestions });
                }} size={20} />
                <input required type="text" className="input w-full" placeholder="Write your question here" value={item.question} onChange={(e) => {
                  const updatedQuestions = [...parsedCode?.pre_defined_questions];
                  updatedQuestions[index] = { ...updatedQuestions[index], question: e.target.value };
                  setParsedCode({ ...parsedCode, pre_defined_questions: updatedQuestions });
                }} />
                  <button className="text-error/500 text-lg" onClick={() => {
                    const updatedQuestions = [...parsedCode?.pre_defined_questions];
                    updatedQuestions.splice(index, 1);
                    setParsedCode({ ...parsedCode, pre_defined_questions: updatedQuestions });
                  }}>
                    <GoTrash />
                  </button>
              </div>
            </div>
          ))}
          <button className="btn-2 border-blue/500" onClick={() => {
            const updatedQuestions = [...parsedCode?.pre_defined_questions];
            updatedQuestions.push({ icon: "FiBox", question: "" });
            setParsedCode({ ...parsedCode, pre_defined_questions: updatedQuestions });
          }}>
            {parsedCode?.pre_defined_questions?.length > 0 ? "Add another question" : "Add a question"}
          </button>
        </div>
      {!botId && (
        <div className="bot-box bg-transparent text-right p-0 space-x-4 mt-8">
          <button
            className="btn-2 text-sm text-error/500 hover:border-error/500 inline"
            onClick={() => navigate("/")}
          >
            Discard
          </button>
          <button className="btn text-sm" onClick={() => createBot()}>
            Create bot
          </button>
        </div>
      )}
      {botId && (
        <div className="bot-box bg-transparent text-right p-0 mt-8">
          <button
            className="btn text-sm"
            onClick={handleSave} // Use handleSave to check model change
          >
            Save
          </button>
        </div>
      )}
      <Modal
        title={`Delete ${parsedCode?.name}`}
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer=""
      >
        <p>Are you sure you want to delete this bot?</p>
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="btn bg-transparent border border-blue/500 text-blue/500"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn bg-error/500" onClick={() => deleteBot()}>
            Delete
          </button>
        </div>
      </Modal>

      <BoxLoader loading={loading} current={current} />
    </div>
  );
};

export default Setup;