import { Modal } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import instance from "../../api";
import { notification } from "../../helper";
import axios from "axios";
import { FiEye } from "react-icons/fi";

const Plugins = () => {
  const [addPluginModal, setAddPluginModal] = useState(false);
  const [plugins, setPlugins] = useState([]);
  const [plugin, setPlugin] = useState({name: "", desc: "", icon: "", url: ""});
  const [viewComponent, setViewComponent] = useState(null);
  // const [moduleMap, setModuleMap] = useState({});

  useEffect(() => {
    getPlugins();
  }, []);
  
  // useEffect(() => {
  //   const map = {};
  //   plugins.forEach(plugin => {
  //     map[plugin.url] = lazy(() => import(plugin.url));
  //   });
  //   setModuleMap(map);
  // }, [plugins]);
  
  const getPlugins = () => {
    instance
      .get(`/plugins`)
      .then((res) => {
        console.log(res);
        setPlugins(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const createPlugin = () => {
    instance
      .post(`/plugins/create`, plugin)
      .then((res) => {
        console.log(res);
        getPlugins();
        setPlugin({name: "", desc: "", icon: "", url: ""});
        setAddPluginModal(false);
        notification.success("Plugin created successfully!");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const refreshAllPlugins = () => {
    axios
      .get(`${process.env.REACT_APP_CHAT_BE_URL}/api/chat/refresh_plugins`)
      .then((res) => {
        console.log(res);
        notification.success("Plugins refreshed successfully!");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const refreshPlugin = (url) => {
    axios
      .get(`${process.env.REACT_APP_CHAT_BE_URL}/api/chat/refresh_plugin/${url}`)
      .then((res) => {
        console.log(res);
        notification.success("Plugins refreshed successfully!");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const moduleMap = {
    'avatar_google_drive': () => import('avatar_google_drive'),
    // Add other mappings as needed
  };
  
  const dynamicComponentLoader = async (url) => {
    try {
      console.log(url, "This is url");
      if (!moduleMap[url]) {
        throw new Error(`Module ${url} is not mapped`);
      }
      const module = await moduleMap[url]();
      console.log(module, "This is module");
      const Component = module.default || module[Object.keys(module)[0]]; // Assuming the component is the default export
      return Component;
    } catch (error) {
      console.error(`Error loading component from ${url}:`, error);
      return null;
    }
  };

  const handleView = async (url) => {
    const Component = await dynamicComponentLoader(url);
    setViewComponent(() => Component);
  };

  return (
    <div className="m-5">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray/950">
          Choose any plugins to boost your bot
        </h1>
        <div className="space-x-3">
          <button className="btn-2 inline" onClick={() => refreshAllPlugins()}>
            Refresh All
          </button>
          <button className="btn" onClick={() => setAddPluginModal(true)}>
            Add Plugin
          </button>
        </div>
      </div>

      <div className="mt-6 flex gap-6 flex-wrap">
        {plugins.map((item) => (
          <div key={item._id} className="w-64 h-40 p-4 bg-white rounded-lg">
            <div className="flex justify-between items-center">
              <div className="plugins-icon">
                <img src={item.icon} alt="" />
              </div>
              <div className="flex items-center gap-2">
                {/* <button className="btn-2 rounded-full size-8 p-0 flex justify-center items-center hover:text-blue/500" onClick={() => handleView(item.url)}>
                  <FiEye className="text-lg" />
                </button> */}
                <button className="btn-2" onClick={() => refreshPlugin(item.url)}>Refresh</button>
              </div>
            </div>
            <div className="mt-2">
              <h2 className="text-base text-gray/950 font-semibold">
                {item.name}
              </h2>
              <p className="text-gray/500 text-sm">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <Modal
        title={`Add Plugin`}
        open={addPluginModal}
        onCancel={() => setAddPluginModal(false)}
        footer=""
      >
        <div className="flex flex-col gap-3">
          <input
            type="text"
            placeholder="Enter the plugin name"
            className="input"
            value={plugin.name}
            onChange={(e) => setPlugin({...plugin, name: e.target.value})}
          />
          <input
            type="text"
            placeholder="Icons8.com -> Copy -> Link to PNG"
            className="input"
            value={plugin.icon}
            onChange={(e) => setPlugin({...plugin, icon: e.target.value})}
          />
          <input
            type="text"
            placeholder="Enter the plugin description"
            className="input"
            value={plugin.desc}
            onChange={(e) => setPlugin({...plugin, desc: e.target.value})}
          />
          <input
            type="text"
            placeholder="Enter the plugin url"
            className="input"
            value={plugin.url}
            onChange={(e) => setPlugin({...plugin, url: e.target.value})}
          />
        </div>
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="btn bg-transparent border border-blue/500 text-blue/500"
            onClick={() => setAddPluginModal(false)}
          >
            Cancel
          </button>
          <button className="btn" onClick={() => createPlugin()}>
            Add
          </button>
        </div>
      </Modal>

      {viewComponent && (
        <Modal
          title="View Plugin"
          open={!!viewComponent}
          onCancel={() => setViewComponent(null)}
          footer=""
        >
          <Suspense fallback={<div>Loading...</div>}>
            {React.createElement(viewComponent)}
          </Suspense>
        </Modal>
      )}
    </div>
  );
};

export default Plugins;
