import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import instance from "../../api";
import { CopyToClipboard, notification } from "../../helper";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useSubscription } from "../../context/SubscriptionContext";

const ApiKey = () => {
  const { currentOrg } = useSubscription();
  const [key, setKey] = useState("");
  const [openaiKey, setOpenaiKey] = useState("");

  useEffect(() => {
    if (currentOrg) {
      getOpenaiKey();
    }
  }, [currentOrg]);

  const getOpenaiKey = () => {
    instance
      .get(`/organizations/get_openai_key/${currentOrg}`)
      .then((res) => {
        setOpenaiKey(maskKey(res.data?.data?.openaiKey));
        setKey(res.data?.data?.openaiKey)
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const saveOpenaiKey = () => {
    const request = {
      orgId: currentOrg,
      openaiKey: openaiKey,
    };
    instance
      .post("/organizations/save-openai-key", request)
      .then((res) => {
        notification.success(res.data.message);
        getOpenaiKey();
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const maskKey = (key) => {
    if (!key) return '';
    const visibleLength = 3; // Number of characters to show
    const maskedLength = key.length - visibleLength; // Calculate the number of masked characters
    const maskedPart = maskedLength > 0 ? '*'.repeat(maskedLength) : ''; // Create the masked part
    return key.slice(0, visibleLength) + maskedPart; // Combine visible and masked parts
  };

  return (
    <div className="m-5">
      <div className="bg-white p-5 rounded-lg shadow-md space-y-3">
        <h2 className="label">AvatarGPT API</h2>
        <p>
          Introducing the AvatarGPT API, powered by various data models. Ingest
          business-specific content, generate precise responses based on your
          content, and create custom chatbots. Seamlessly integrate ChatGPT into
          your workflows and create apps for enhanced decision making and
          efficiency. See API Documentation.
        </p>
      </div>

      <div className="bg-white p-5 rounded-lg shadow-md space-y-3 mt-3">
        <h2 className="label">API Key</h2>
      </div>

      <div className="bg-white p-5 rounded-lg shadow-md space-y-3 mt-10">
        <h2 className="label">OpenAI Key</h2>
        <p>Enter your OpenAI API key to use the OpenAI models.</p>
        <div className="flex items-center gap-3">
          <input
            type="text"
            className="input w-full"
            placeholder="Enter your OpenAI API key"
            value={openaiKey}
            onChange={(e) => setOpenaiKey(e.target.value)}
          />
          <CopyToClipboard textToCopy={key}>
            <div className="btn-2 p-2.5 shrink-0">
              <IoCopyOutline className="text-xl" />
            </div>
          </CopyToClipboard>
        </div>
        <button className="btn" onClick={saveOpenaiKey}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ApiKey;
