import React, { useEffect, useState } from "react";
import {
  GoogleDrive,
  Website,
  Code,
  Postgres,
  Api,
  Confluence,
} from "avatar_google_drive";
import { FaCode, FaGoogleDrive, FaXmark, FaConfluence, FaBolt } from "react-icons/fa6";
import { MdOutlineLaptopChromebook } from "react-icons/md";
import { PiGlobe } from "react-icons/pi";
import Dragger from "antd/es/upload/Dragger";
import { BsCloudUpload, BsFileEarmarkPdf } from "react-icons/bs";
import { BoxLoader, notification } from "../../../../helper";
import { Drawer, Modal, Upload, Radio } from "antd";
import instance from "../../../../api";
import { BsDatabase } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { AiOutlineApi } from "react-icons/ai";
import { FiLink } from "react-icons/fi";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import { useSubscription } from "../../../../context/SubscriptionContext";

const Data = ({ code, setCode, getBot, plugins, setPlugins }) => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [googleFiles, setGoogleFiles] = useState([]);
  const [files, setFiles] = useState({ new: [], uploaded: [] });
  const [websiteLinks, setWebsiteLinks] = useState({
    base_url: '',
    data_source: []
  });
  const [codeLinks, setCodeLinks] = useState([]);
  const [postgresqlLinks, setPostgresqlLinks] = useState({});
  const [apiObj, setApiObj] = useState({});
  const [confluenceObj, setConfluenceObj] = useState({});
  const [pluginsModal, setPluginsModal] = useState(false);
  const [fileDrawer, setFileDrawer] = useState(false);
  const [gdriveDrawer, setGdriveDrawer] = useState(false);
  const [websiteDrawer, setWebsiteDrawer] = useState(false);
  const [codeDrawer, setCodeDrawer] = useState(false);
  const [postgresqlDrawer, setPostgresqlDrawer] = useState(false);
  const [apiDrawer, setApiDrawer] = useState(false);
  const [confluenceDrawer, setConfluenceDrawer] = useState(false);
  const [removePluginModal, setRemovePluginModal] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState();
  const { botId } = useParams();
  const { currentOrg, orgDetails, getOrgById } = useSubscription();
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [websiteRadio, setWebsiteRadio] = useState('');

  const MAX_FILE_SIZE = 3 * 1024 * 1024;

  useEffect(() => {
    const codeObject = JSON.parse(code);
    codeObject.providers.forEach((provider) => {
      if (provider.plugin_name === "avatar_file_documents") {
        setPlugins((prev) => [...prev, "file"]);
        setFiles({ ...files, uploaded: provider.additional_data.file_urls });
      }
      if (provider.plugin_name === "avatar_web_documents") {
        setPlugins((prev) => [...prev, "website"]);
        setWebsiteLinks(provider.additional_data);
      
        if (provider.additional_data.base_url) {
          setWebsiteRadio("base");
        } else if (provider.additional_data.data_source && provider.additional_data.data_source.length > 0) {
          setWebsiteRadio("data");
        }
      }
      if (provider.plugin_name === "avatar_sql_reader") {
        setPlugins((prev) => [...prev, "database"]);
        setPostgresqlLinks(provider.additional_data.db_config);
      }
      if (provider.plugin_name === "avatar_swagger_api") {
        setPlugins((prev) => [...prev, "api"]);
        setApiObj(provider.additional_data);
      }
      if (provider.plugin_name === "avatar_confluence") {
        setPlugins((prev) => [...prev, "confluence"]);
        setConfluenceObj(provider.additional_data);
      }
    });
  }, [code]);

  const beforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      notification.error("File must be less than 3MB");
      return Upload.LIST_IGNORE; // This will prevent the file from being added to the list
    }
    return false; // Return false to prevent upload
  };

  const pluginList = [
    {
      key: "file",
      name: "Upload Files",
      icon: <MdOutlineLaptopChromebook className="text-gray/500" />,
      disabled: false
    },
    // {
    //   key: "googleDrive",
    //   name: "Google Drive",
    //   icon: <FaGoogleDrive className="text-gray/500" />,
    // },
    {
      key: "website",
      name: "Website",
      icon: <PiGlobe className="text-gray/500" />,
      disabled: false
    },
    // {
    //   key: "code",
    //   name: "Code",
    //   icon: <FaCode className="text-gray/500" />,
    // },
    // {
    //   key: "database",
    //   name: "Database",
    //   icon: <BsDatabase className="text-gray/500" />,
    // },
    // {
    //   key: "api",
    //   name: "API",
    //   icon: <AiOutlineApi className="text-gray/500" />,
    // },
    {
      key: "confluence",
      name: "Confluence",
      icon: <FaConfluence className="text-gray/500" />,
      disabled: !orgDetails?.confluence_support
    },
  ];

  const drawerMap = {
    file: setFileDrawer,
    googleDrive: setGdriveDrawer,
    website: setWebsiteDrawer,
    code: setCodeDrawer,
    database: setPostgresqlDrawer,
    api: setApiDrawer,
    confluence: setConfluenceDrawer,
  };

  const handleAddFile = (fileArray) => {
    const newFiles = fileArray.filter(
      (file) => !googleFiles.some((existingFile) => existingFile.id === file.id)
    );
    setGoogleFiles([...googleFiles, ...newFiles]);
  };

  const handleAddWebsiteLink = (link) => {
    setWebsiteLinks(link);
  };

  const handleAddCode = (link) => {
    setCodeLinks([...codeLinks, link]);
  };

  const handleAddPostgres = (link) => {
    setPostgresqlLinks(link);
  };

  const handleAddAPi = (link) => {
    setApiObj(link);
  };

  const handleAddConfluence = (link) => {
    setConfluenceObj(link);
  };

  const handleSave = async () => {
    // Step 1: Parse the existing code string into an object
    const codeObject = JSON.parse(code);

    // Initialize an array to hold all new providers
    const newProviders = [];

    // Check and handle googleFiles
    if (googleFiles.length > 0) {
      const createProvider = (type, pluginName, fileData) => ({
        type: type,
        plugin_name: pluginName,
        additional_data: {
          file_urls: fileData.map((file) => file.id),
        },
      });

      const googleProvider = createProvider(
        "googleDrive",
        "Google Drive Plugin",
        googleFiles
      );
      newProviders.push(googleProvider);
    }

    // Check and handle websiteLinks
    setWebsiteDrawer(false);
    const existingWebsiteProviderIndex = codeObject.providers.findIndex(
      (provider) => provider.plugin_name === "avatar_web_documents"
    );

    // Check if websiteLinks is empty and remove the provider if necessary
    if (!websiteLinks.base_url && (!websiteLinks.data_source || websiteLinks.data_source.length === 0)) {
      if (existingWebsiteProviderIndex !== -1) {
        // console.log('Removing provider because websiteLinks is empty');
        codeObject.providers.splice(existingWebsiteProviderIndex, 1);
        setPlugins(plugins.filter((p) => p !== "website"));
      }
    } else {
      // Update or create the provider if websiteLinks is not empty
      if (existingWebsiteProviderIndex !== -1) {
        // Update the existing provider's additional_data
        codeObject.providers[existingWebsiteProviderIndex].additional_data = {
          ...websiteLinks,
        };
      } else {
        // Create a new provider
        const createProvider = (type, pluginName, fileData) => ({
          type: type,
          plugin_name: pluginName,
          additional_data: {
            ...fileData,
          },
        });
        const websiteProvider = createProvider(
          "input",
          "avatar_web_documents",
          websiteLinks
        );
        newProviders.push(websiteProvider);
      }
    }

    // Check and handle codeLinks
    if (codeLinks.length > 0) {
      const createProvider = (type, pluginName, fileData) => ({
        type: type,
        plugin_name: pluginName,
        additional_data: {
          file_urls: fileData.map((file) => file),
        },
      });

      const codeProvider = createProvider("code", "Code Plugin", codeLinks);
      newProviders.push(codeProvider);
    }

    // Check and handle postgresqlLinks
    setPostgresqlDrawer(false);
    const existingPostgresqlProviderIndex = codeObject.providers.findIndex(
      (provider) => provider.plugin_name === "avatar_sql_reader"
    );

    // Define the main data fields to check
    const mainDataFields = ['type', 'host', 'port', 'dbname', 'username', 'password'];

    // Check if all main data fields are empty
    const isPostgresqlLinksEmpty = mainDataFields.every(field => !postgresqlLinks[field]);

    if (isPostgresqlLinksEmpty) {
      if (existingPostgresqlProviderIndex !== -1) {
        // console.log('Removing provider because postgresqlLinks main data fields are empty');
        codeObject.providers.splice(existingPostgresqlProviderIndex, 1);
        setPlugins(plugins.filter((p) => p !== "database"));
      }
    } else {
      // Update or create the provider if postgresqlLinks is not empty
      if (existingPostgresqlProviderIndex !== -1) {
        // Update the existing provider's additional_data
        codeObject.providers[existingPostgresqlProviderIndex].additional_data.db_config = {
          ...postgresqlLinks,
        };
      } else {
        // Create a new provider
        const createProvider = (type, pluginName, fileData) => ({
          type: type,
          plugin_name: pluginName,
          additional_data: {
            db_config: fileData,
          },
        });
        const postgresqlProvider = createProvider(
          "plugin",
          "avatar_sql_reader",
          postgresqlLinks
        );
        newProviders.push(postgresqlProvider);
      }
    }

    // Check and handle apiObj
    setApiDrawer(false);
    const existingApiProviderIndex = codeObject.providers.findIndex(
      (provider) => provider.plugin_name === "avatar_swagger_api"
    );

    // Check if apiObj is empty based on main fields
    const isApiObjEmpty = !apiObj.base_url && !apiObj.swagger_json_api_url;

    if (isApiObjEmpty) {
      if (existingApiProviderIndex !== -1) {
        // console.log('Removing provider because apiObj main data fields are empty');
        codeObject.providers.splice(existingApiProviderIndex, 1);
        setPlugins(plugins.filter((p) => p !== "api"));
      }
    } else {
      // Update or create the provider if apiObj is not empty
      if (existingApiProviderIndex !== -1) {
        // Update the existing provider's additional_data
        codeObject.providers[existingApiProviderIndex].additional_data = {
          ...apiObj,
        };
      } else {
        // Create a new provider
        const createProvider = (type, pluginName, fileData) => ({
          type: type,
          plugin_name: pluginName,
          additional_data: {
            ...fileData,
          },
        });
        const apiProvider = createProvider(
          "plugin",
          "avatar_swagger_api",
          apiObj
        );
        newProviders.push(apiProvider);
      }
    }

    // Check and handle confluenceObj
    setConfluenceDrawer(false);
    const existingConfluenceProviderIndex = codeObject.providers.findIndex(
      (provider) => provider.plugin_name === "avatar_confluence"
    );

    // Check if confluenceObj is empty based on main fields
    const isConfluenceObjEmpty = !confluenceObj.user && !confluenceObj.server && !confluenceObj.apikey;

    if (isConfluenceObjEmpty) {
      if (existingConfluenceProviderIndex !== -1) {
        // console.log('Removing provider because confluenceObj main data fields are empty');
        codeObject.providers.splice(existingConfluenceProviderIndex, 1);
        setPlugins(plugins.filter((p) => p !== "confluence"));
      }
    } else {
      // Update or create the provider if confluenceObj is not empty
      if (existingConfluenceProviderIndex !== -1) {
        // Update the existing provider's additional_data
        codeObject.providers[existingConfluenceProviderIndex].additional_data = {
          ...confluenceObj,
        };
      } else {
        // Create a new provider
        const createProvider = (type, pluginName, fileData) => ({
          type: type,
          plugin_name: pluginName,
          additional_data: {
            ...fileData,
          },
        });
        const confluenceProvider = createProvider(
          "input",
          "avatar_confluence",
          confluenceObj
        );
        newProviders.push(confluenceProvider);
      }
    }

    // Check and handle local files
    let hasError = false; // Flag to track errors

    if (plugins.includes("file")) {
      setFileDrawer(false);
      try {
        let uploadedFilePaths = [];
        if (files.new.length > 0) {
          const uploadedFiles = await uploadFiles(files.new);
          uploadedFilePaths = uploadedFiles.map((file) => file.file_path);
        }

        const existingFileProviderIndex = codeObject.providers.findIndex(
          (provider) => provider.plugin_name === "avatar_file_documents"
        );

        const allFilePaths = [
          ...uploadedFilePaths,
          ...files.uploaded.map((file) => file),
        ];

        if (existingFileProviderIndex !== -1) {
          // Update the existing provider's additional_data
          codeObject.providers[
            existingFileProviderIndex
          ].additional_data.file_urls = allFilePaths;
        } else {
          // Create a new provider
          const createProvider = (type, pluginName, fileData) => ({
            type: type,
            plugin_name: pluginName,
            additional_data: {
              file_urls: fileData,
            },
          });
          const fileProvider = createProvider(
            "input",
            "avatar_file_documents",
            allFilePaths
          );
          newProviders.push(fileProvider);
        }
        setFiles({ ...files, new: [] });
      } catch (error) {
        // Handle errors from file upload, e.g., show a notification
        console.error("Failed to upload files:", error);
        hasError = true; // Set error flag
      }
    }

    // Step 3: Add the new providers to the providers array in the code object
    if (newProviders.length > 0) {
      codeObject.providers = [...codeObject.providers, ...newProviders];

      // Step 4: Convert the updated code object back into a JSON string
      const updatedCodeString = JSON.stringify(codeObject, null, 2);

      // Step 5: Update the state with the new code string or handle it as needed
      setCode(updatedCodeString);
    }

    // Step 6: Update the bot with the new code object
    if (filesToDelete.length > 0) {
      try {
        await deleteFileProvider();
      } catch (error) {
        console.error("Failed to delete file provider:", error);
        hasError = true; // Set error flag
      }
    }

    if (!hasError) {
      await updateBot(codeObject);
      await getOrgById(orgDetails.org_id);
    }
  };

  const uploadFiles = async () => {
    setLoading(true);
    const form = new FormData();
    files.new.forEach((file) => {
      form.append("files", file.originFileObj);
    });

    try {
      const response = await instance.post(`/provider/create/${currentOrg}/${botId}`, form);
      setLoading(false);
      // notification.success("Files uploaded successfully");
      return response.data.data;
    } catch (err) {
      console.log(err?.response?.data?.message.error, "This is upload err");
      notification.error(err?.response?.data?.message?.error);
      setLoading(false);
    }
  };

  const updateBot = async (data) => {
    setLoading(true);
    setCurrent(0);
    try {
      const response = await instance.put(`/bot/update/${botId}`, { ...data });
      // console.log(response);
      setLoading(false);
      getBot();
      handleGenerate();
      notification.success("Bot updated successfully!");
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error(err?.response?.data?.message?.error);
    }
  };

  const handleGenerate = () => {
    setCurrent(1);
    setLoading(true);
    instance
      .post(`/api/generate`, { botId })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        notification.success("Bot generated successfully!");
        getBot();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const deleteFileProvider = async () => {
    await instance
      .post(`/provider/delete/${currentOrg}`, { data: filesToDelete })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setFilesToDelete([]);
        // notification.success("Files deleted successfully!");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const pluginProviderMap = {
    file: {
      providerName: 'avatar_file_documents',
    },
    googleDrive: {
      providerName: 'avatar_google_drive',
    },
    website: {
      providerName: 'avatar_web_documents',
    },
    code: {
      providerName: 'avatar_code_documents',
    },
    database: {
      providerName: 'avatar_sql_reader',
    },
    api: {
      providerName: 'avatar_swagger_api',
    },
    confluence: {
      providerName: 'avatar_confluence',
    },
  };

  const pluginCheck = (pluginKey) => {
    const codeObject = JSON.parse(code);
    const pluginConfig = pluginProviderMap[pluginKey];
  
    if (pluginConfig) {
      const providerExists = codeObject.providers.some(provider => provider.plugin_name === pluginConfig.providerName);
      if (providerExists) {
        setSelectedPlugin({ key: pluginKey, providerName: pluginConfig.providerName });
        setRemovePluginModal(true);
      } else {
        setPlugins(plugins.filter((p) => p !== pluginKey));
      }
    } else {
      setPlugins(plugins.filter((p) => p !== pluginKey));
    }
  };

  const handleDeletePlugin = async () => {
    const codeObject = JSON.parse(code);
    const providerIndex = codeObject.providers.findIndex(provider => provider.plugin_name === selectedPlugin.providerName);
  
    if (providerIndex !== -1) {
      codeObject.providers.splice(providerIndex, 1);
      setCode(JSON.stringify(codeObject, null, 2));
      setPlugins(plugins.filter((p) => p !== selectedPlugin.key));
    }
    await updateBot(codeObject);
    setRemovePluginModal(false);
  };

  const SaveButton = () => (
    <button
      className="btn w-5/6 absolute bottom-4 left-0 right-0 mx-auto"
      onClick={handleSave}
      disabled={(orgDetails?.status === 'active' || orgDetails?.free_trial === 'active') ? false : true}
    >
      Save
    </button>
  );

  const onWebRadioChange = (e) => {
    const value = e.target.value;
    setWebsiteRadio(value);
  
    setWebsiteLinks((prevLinks) => {
      if (value === 'base') {
        // Set data_source to an empty array when 'base' is selected
        return { ...prevLinks, data_source: [] };
      } else if (value === 'data') {
        // Set base_url to an empty string when 'data' is selected
        return { ...prevLinks, base_url: '' };
      }
      return prevLinks;
    });
  };

  return (
    <div className="m-8 space-y-8">
      <div className="banner text-white p-5 rounded-3xl">
        <h1 className="grotesk font-semibold text-2xl">
          Connect your knowledge to train your bot
        </h1>
        <h2 className="text-gray/500 text-base font-medium mt-1">
          Start by uploading files or connect with other apps
        </h2>
        <button
          className="btn-2 mt-6 text-sm border-white text-white hover:border-white"
          onClick={() => setPluginsModal(true)}
        >
          Add Datasources
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4 2xl:w-3/4">
        {pluginList.map(
          (plugin) =>
            plugins.includes(plugin.key) && (
              <div
                key={plugin.key}
                className="bot-box w-full mx-0 flex items-center justify-between border hover:border-blue/500 cursor-pointer"
                onClick={() => drawerMap[plugin.key](true)}
              >
                <div className="flex items-center gap-2">
                  <h2 className="text-2xl">{plugin.icon}</h2>
                  <h1>{plugin.name}</h1>
                </div>
                <div className="flex items-center gap-6">
                  <h2 className="text-blue/500 text-lg">Open</h2>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      pluginCheck(plugin.key);
                      // setPlugins(plugins.filter((p) => p !== plugin.key));
                    }}
                  >
                    <HiOutlineTrash className="text-2xl text-error/500" />
                  </button>
                </div>
              </div>
            )
        )}
      </div>

      <Drawer
        title={<h1 className="flex items-center gap-2"><MdOutlineLaptopChromebook className="text-xl"/>Upload Files</h1>}
        onClose={() => setFileDrawer(false)}
        open={fileDrawer}
      >
        <div className="">
          <p className="text-gray/500 font-normal pb-6">
            Select files from your system
          </p>
          <Dragger
            className=""
            accept={".pdf,.txt,.csv,.zip"}
            style={{
              backgroundColor: "#ECEDF2",
              border: "2px dashed #16171D",
              padding: "10px",
            }}
            beforeUpload={beforeUpload}
            fileList={[]}
            multiple
            maxCount={10}
            onChange={(e) =>
              setFiles({ ...files, new: [...files.new, ...e.fileList] })
            }
          >
            <div className="flex flex-col justify-center items-center gap-1 text-gray/950">
              <BsCloudUpload className="text-3xl" />
              <h2 className="text-sm font-medium">
                Drag and Drop file here or{" "}
                <span className="underline">Choose file</span>
              </h2>
              <h2 className="text-gray/500 text-sm font-medium">
                Supports file .pdf,.zip,.txt,.csv
              </h2>
            </div>
          </Dragger>
          <div className="mt-4 space-y-4">
            {files.new.map((item) => (
              <div
                key={item?.uid}
                className="flex justify-between items-start gap-5 bg-white p-3 rounded-lg border border-gray/200"
              >
                <div className="flex items-start gap-2 text-gray/950 font-medium">
                  <BsFileEarmarkPdf className="text-xl text-red-500 shrink-0" />
                  {item?.name}
                </div>
                <button
                  onClick={() =>
                    setFiles({
                      ...files,
                      new: files.new.filter((file) => file.uid !== item.uid),
                    })
                  }
                >
                  <HiOutlineTrash className="text-xl text-error/500" />
                </button>
              </div>
            ))}
            {files.uploaded.map((item) => (
              <div
                key={item}
                className="flex justify-between items-start gap-5 bg-white p-3 rounded-lg border border-gray/200"
              >
                <div className="flex items-start gap-2 text-gray/950 font-medium">
                  <BsFileEarmarkPdf className="text-xl text-red-500 shrink-0" />
                  {item.split('/').pop()}
                </div>
                <button
                  onClick={() =>{
                    setFilesToDelete([...filesToDelete, item]);
                    setFiles({
                      ...files,
                      uploaded: files.uploaded.filter((file) => file !== item),
                    })
                  }
                  }
                >
                  <HiOutlineTrash className="text-xl text-error/500" />
                </button>
              </div>
            ))}
          </div>
          <SaveButton />
        </div>
      </Drawer>

      <Drawer
        title="Google Drive"
        onClose={() => setGdriveDrawer(false)}
        open={gdriveDrawer}
      >
        <div className="space-y-4">
          <p className="text-gray/500 font-normal">
            Make sure the link is a share to anyone with link
          </p>
          <div className="flex items-center gap-4">
            <GoogleDrive
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              handleAddFile={handleAddFile}
              developerKey={process.env.REACT_APP_GOOGLE_DEVELOPER_KEY}
            />
          </div>
          <div>
            {googleFiles.map((item) => (
              <p key={item.id}>{item.name}</p>
            ))}
          </div>
        </div>
      </Drawer>

      <Drawer
        title={<h1 className="flex items-center gap-2"><PiGlobe className="text-xl"/>Website</h1>}
        onClose={() => setWebsiteDrawer(false)}
        open={websiteDrawer}
      >
        <div className="space-y-4">
          <p className="text-gray/500 font-normal">
            Include links to websites you wish extracted.
          </p>
          <Radio.Group value={websiteRadio} onChange={onWebRadioChange} >
            <Radio value={'base'}>Base URL</Radio>
            <Radio value={'data'}>Datasource</Radio>
          </Radio.Group>
          {/* <Website handleAddWebsiteLink={handleAddWebsiteLink} /> */}
          {websiteRadio === 'base' && <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Base URL</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="Enter the base url of the website"
              value={websiteLinks?.base_url || ""}
              onChange={(e) =>
                setWebsiteLinks({ ...websiteLinks, base_url: e.target.value })
              }
              />
          </div>}
          {websiteRadio === 'data' && <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Datasource</h2>
            {websiteLinks?.data_source?.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  key={index}
                  type="text"
                  className="input w-full"
                  placeholder="Enter the datasource"
                  value={item}
                  onChange={(e) => {
                    const newDataSource = [...websiteLinks.data_source];
                    newDataSource[index] = e.target.value;
                    setWebsiteLinks({ ...websiteLinks, data_source: newDataSource });
                  }}
                />
                <button onClick={() => {
                  const newDataSource = [...websiteLinks.data_source];
                  newDataSource.splice(index, 1);
                  setWebsiteLinks({ ...websiteLinks, data_source: newDataSource });
                }}>
                  <HiOutlineTrash className="text-xl text-error/500" />
                </button>
              </div>
            ))}
            <div className="flex justify-end">
              <button
                className="btn-2"
                onClick={() => {
                  if (websiteLinks.data_source.length < orgDetails.sites_to_scrape) {
                    setWebsiteLinks({ ...websiteLinks, data_source: [...websiteLinks.data_source, ""] });
                  } else {
                    alert(`You can only add up to ${orgDetails.sites_to_scrape} data sources.`);
                  }
                }}
                disabled={websiteLinks.data_source.length >= orgDetails.sites_to_scrape}
              >
                <HiOutlinePlus />
                Source
              </button>
            </div>
            {websiteLinks.data_source.length >= orgDetails.sites_to_scrape && <p className="text-error/500 text-sm text-center mt-5">Limit Reached! Upgrade plan to add more links</p>}
          </div>}
          <SaveButton />
        </div>
      </Drawer>

      <Drawer
        title="Code"
        onClose={() => setCodeDrawer(false)}
        open={codeDrawer}
      >
        <div className="space-y-4">
          <p className="text-gray/500 font-normal">
            Make sure the link is a share to anyone with link
          </p>
          <Code handleAddCode={handleAddCode} />
          <div>
            {codeLinks.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        </div>
      </Drawer>

      <Drawer
        title={<h1 className="flex items-center gap-2"><BsDatabase className="text-xl"/>Database</h1>}
        onClose={() => setPostgresqlDrawer(false)}
        open={postgresqlDrawer}
      >
        <div className="space-y-4 mt-4">
          {/* <Postgres handleAddPostgres={handleAddPostgres} /> */}
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Choose your database</h2>
            <select
              type="text"
              className="input w-full appearance-none pr-8" // Adjust padding to shift the arrow
              value={postgresqlLinks?.type || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, type: e.target.value })
              }
            >
              <option value="" >Select a database</option>
              <option value="postgresql">postgresql</option>
              <option value="mysql">mysql</option>
              <option value="sqlite">sqlite</option>
            </select>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Host</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="Enter the host"
              value={postgresqlLinks?.host || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, host: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">The IP address or hostname (db.example.com) of where your database instance resides. Note, localhost and 127.0.0.1 are not valid! Make sure it is accessible over the internet</p>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Port</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="123"
              value={postgresqlLinks?.port || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, port: e.target.value })
              }
              />
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Database Name</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="servicedata"
              value={postgresqlLinks?.dbname || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, dbname: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">The name of the database you would like to interact with.</p>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Username</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="johndoe"
              value={postgresqlLinks?.username || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, username: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">We recommend creating a new database user with extremely limited access.</p>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Password</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="password"
              value={postgresqlLinks?.password || ""}
              onChange={(e) =>
                setPostgresqlLinks({ ...postgresqlLinks, password: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">Again, this should not be your production password. Make it strong! (We like using random.org.)</p>
          </div>
          <SaveButton />
        </div>
      </Drawer>

      <Drawer title={<h1 className="flex items-center gap-2"><AiOutlineApi className="text-xl"/>API</h1>} onClose={() => setApiDrawer(false)} open={apiDrawer}>
        <div className="space-y-4 mt-4">
          {/* <p className="text-gray/500 font-normal">
            Make sure the link is a share to anyone with link
          </p> */}
          {/* <Api handleAddAPi={handleAddAPi} /> */}
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Swagger json API URL</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="https://example.com/api/v1/swagger.json"
              value={apiObj?.swagger_json_api_url || ""}
              onChange={(e) =>
                setApiObj({ ...apiObj, swagger_json_api_url: e.target.value })
              }
              />
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Base API URL</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="https://example.com"
              value={apiObj?.base_api_url || ""}
              onChange={(e) =>
                setApiObj({ ...apiObj, base_api_url: e.target.value })
              }
              />
          </div>
          <SaveButton />
        </div>
      </Drawer>

      <Drawer
        title={<h1 className="flex items-center gap-2"><FaConfluence className="text-lg"/>Confluence</h1>}
        onClose={() => setConfluenceDrawer(false)}
        open={confluenceDrawer}
      >
        <div className="space-y-4 mt-4">
          {/* <p className="text-gray/500 font-normal">
            Make sure the link is a share to anyone with link
          </p> */}
          {/* <Confluence handleAddConfluence={handleAddConfluence} /> */}
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">User Email</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="john.doe@example.com"
              value={confluenceObj?.user || ""}
              onChange={(e) =>
                setConfluenceObj({ ...confluenceObj, user: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">The email address for the Atlassian account you're using to create the API Token.</p>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">Sever</h2>
            <input
              type="text"
              className="input w-full"
              placeholder="https://example.com/confluence"
              value={confluenceObj?.server || ""}
              onChange={(e) =>
                setConfluenceObj({ ...confluenceObj, server: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">Please provide the full URL domain without /wiki or /confluence (e.g., https://example.com/). Be sure to include http:// or https://. It can be found in your browser address bar after logging in.</p>
          </div>
          <div className="space-y-2">
            <h2 className="text-gray/950 font-medium">API Key</h2>
            <textarea
              rows={4}
              type="text"
              className="input w-full"
              placeholder="Enter the api key"
              value={confluenceObj?.apikey || ""}
              onChange={(e) =>
                setConfluenceObj({ ...confluenceObj, apikey: e.target.value })
              }
              />
              <p className="text-gray/400 text-xs font-medium">The API Token created here: http://zpr.io/6uTGN</p>
          </div>
          <SaveButton />
        </div>
      </Drawer>

      <Modal
        title={`Select a Plugin`}
        open={pluginsModal}
        onCancel={() => setPluginsModal(false)}
        footer={null}
        centered
        width={350}
      >
        <div className="mt-4 space-y-2">
          {pluginList.map((item) => (
            <button
              key={item.key}
              className={`flex justify-between items-center gap-2 w-full`}
              onClick={() => {
                if (!plugins.includes(item.key)) {
                  setPlugins([...plugins, item.key]);
                  setPluginsModal(false);
                } else setPluginsModal(false);
              }}
              disabled={item?.disabled}
            >
              <div className="flex items-center gap-2">
                <h2 className="text-base">{item.icon}</h2>
                <h1>{item.name}</h1>
              </div>
              {item?.disabled && 
              <p className="flex items-center gap-1 text-gray/500">
                <FaBolt className="text-gray/300" />
                Upgrade
              </p>}
            </button>
          ))}
        </div>
        <div className="border-b text-gray-200 mt-2"></div>
        <p className="mt-3 text-sm">Looking for custom integration? <button className="text-blue/500" onClick={() => window.location.href = 'mailto:umesh@spirinova.dev'}>Contact Us</button></p>
      </Modal>

      <Modal
        title={`Remove plugin?`}
        open={removePluginModal}
        onCancel={() => setRemovePluginModal(false)}
        footer={null}
      >
        <p>Are you sure you want to remove this plugin?</p>
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="btn bg-transparent border border-blue/500 text-blue/500"
            onClick={() => setRemovePluginModal(false)}
          >
            Cancel
          </button>
          <button className="btn bg-error/500" onClick={handleDeletePlugin}>
            Delete
          </button>
        </div>
      </Modal>

      <BoxLoader loading={loading} current={current} />
    </div>
  );
};

export default Data;
