import { useLogto } from '@logto/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import instance from '../../api';
import { Loader, notification } from '../../helper';
import useAccessToken from '../../hooks/useAccessToken';
import { useSubscription } from '../../context/SubscriptionContext';

const Join = ({ user }) => {
  const { inviteId } = useParams();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getTokens } = useSubscription();

  const { isAuthenticated } = useLogto();
  const fetchAccessToken = useAccessToken();

  useEffect(() => {
    if(isAuthenticated && user) {
      getInviteStatus()
    }
  }, [user])

  const getInviteStatus = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .get(`/organizations/invite/${inviteId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is status");
        setLoading(false);
        if(res?.data?.data?.status === "Pending" && res?.data?.data?.invitee === user?.email) {
          acceptInvite()
        }
        else if(res?.data?.data?.status === "Accepted") {
          setStatus("You are already accepted")
          navigate("/")
        }
        else if(res?.data?.data?.status === "Revoked") {
          setStatus("This link as been revoked")
        }
        else if(res?.data?.data?.status === "Expired") {
          setStatus("This link has expired")
        }
        else if(res?.data?.data?.invitee !== user?.email) {
          setStatus("The logged in user does not match the invited email")
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.message.error);
        setStatus("The link does not exist")
        setLoading(false);
      });
  };

  const acceptInvite = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post(`/organizations/invite/accept/${inviteId}`, { acceptedUserId: user?.sub, status: "Accepted" }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is invite");
        notification.success('Added to organization successfully!');
        getTokens();
        navigate("/")
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <Loader spin={loading}>
        <div className='text-2xl font-bold flex justify-center items-center h-screen'>{status}</div>
    </Loader>
  )
}

export default Join