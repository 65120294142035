import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import instance from "../../../../api";
import MonacoEditor, { monaco } from "react-monaco-editor";
import { BoxLoader, notification } from "../../../../helper";
import { useNavigate, useParams } from "react-router-dom";
import { useSubscription } from "../../../../context/SubscriptionContext";
import { FaX } from "react-icons/fa6";

const Create = ({ user, code, setCode, json }) => {
  const { botId } = useParams();
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(true);
  const { canCreateBot, currentOrg } = useSubscription();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();

  monaco.editor.defineTheme("myCustomTheme", {
    base: "vs", // can also be vs-dark or hc-black
    inherit: true,
    rules: [{ background: "EDF9FA" }],
    colors: {
      "editor.foreground": "#021454",
      "editorCursor.foreground": "#021454",
      "editorLineNumber.foreground": "#021454",
      "editor.background": "#FFF",
      "editor.lineHighlightBackground": "#D8DCE8",
      // "editor.selectionBackground": "#88000030",
      // "editor.inactiveSelectionBackground": "#88000015",
    },
  });

  const editorOptions = {
    theme: "myCustomTheme",
    selectOnLineNumbers: true,
    autoClosingBrackets: "always",
    formatOnType: true,
    formatOnPaste: true,
    autoIndent: "full",
    minimap: { enabled: false },
    validate: true,
    scrollBeyondLastLine: false,
    automaticLayout: true,
    quickSuggestions: true,
    highlightActiveIndentGuide: true,
    renderLineHighlight: "all",
  };

  const handleEditorChange = (newValue, e) => {
    try {
      JSON.parse(newValue); // This will throw an error if JSON is invalid
      setCode(newValue);
      // Optionally reset error state or enable a save button
    } catch (error) {
      //   console.error("Invalid JSON", error);
      // Optionally set error state or disable a save button
    }
  };

  const editorDidMount = (editor, monaco) => {
    editor.focus();
    const model = editor.getModel();

    model.onDidChangeContent(() => {
      validateJson(model, monaco);
    });
  };

  const validateJson = (model, monaco) => {
    const json = model.getValue();
    try {
      JSON.parse(json);
      monaco.editor.setModelMarkers(model, "json", []);
    } catch (e) {
      const lineNumber = e.message.match(/at position (\d+)/);
      const position = lineNumber ? parseInt(lineNumber[1], 10) : 0;
      const line = model.getPositionAt(position).lineNumber;

      monaco.editor.setModelMarkers(model, "json", [
        {
          startLineNumber: line,
          startColumn: 1,
          endLineNumber: line,
          endColumn: 1,
          message: e.message,
          severity: monaco.MarkerSeverity.Error,
        },
      ]);
    }
  };

  const createBot = () => {
    if (!canCreateBot()) return;
    setLoading(true);
    instance
      .post("/bot/create", {
        ...JSON.parse(code),
        org_id: currentOrg,
        created_by: user?.email
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate(`/bot/edit/${res.data.data._id}`);
        notification.success("Bot created successfully!");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err");
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const updateBot = () => {
    setLoading(true);
    setCurrent(0);
    instance
      .put(`/bot/update/${botId}`, {
        ...JSON.parse(code),
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot updated successfully!");
        handleGenerate();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  const handleGenerate = () => {
    setCurrent(1);
    setLoading(true);
    instance
      .post(
        `/api/generate`,
        { botId: botId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot generated successfully!");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  return (
    <div className={`flex flex-col h-full relative ${json && "h-screen"}`}>
      {showWarning && <div className="bg-[#FFB524] p-2 px-6 flex justify-between items-center">
        <div></div>
        <h2 className="text-gray/950 font-medium">Making changes to the code directly can have unintended consequences. Please proceed only if you understand the bot schema as per our documentation.</h2>
        <button onClick={() => setShowWarning(false)}>
          <FaX className="text-gray/950 text-xs" />
        </button>
      </div>}
      <div className="flex-1 overflow-auto overflow-x-hidden relative overscroll-none m-5 rounded-lg">
        <MonacoEditor
          width="100%"
          height="100%"
          language="json"
          theme="myCustomTheme"
          value={code}
          options={editorOptions}
          onChange={handleEditorChange}
          editorDidMount={editorDidMount}
        />
      </div>

      <div className="bg-white p-3 px-5 text-base border-t border-gray/200 drop-shadow flex justify-end sticky bottom-0 w-full">
        {!botId && (
          <button className="btn text-base px-8" onClick={createBot}>
            Create
          </button>
        )}
        {botId && (
          <button className="btn text-base px-8" onClick={updateBot}>
            Update
          </button>
        )}
      </div>
      <BoxLoader loading={loading} current={current} />
    </div>
  );
};

export default Create;
