import React, { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { LuFolders, LuPanelLeftClose, LuPanelLeftOpen } from "react-icons/lu";
import { Menu, Modal, Popover } from "antd";
import { IoExtensionPuzzleOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineChat, MdOutlineSupport } from "react-icons/md";
import { BiBot } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { VscKey } from "react-icons/vsc";
import { FaCaretDown, FaCheck, FaCodeBranch, FaPlus, FaWhatsapp } from "react-icons/fa6";
import { AiOutlineTeam } from "react-icons/ai";
import { useSubscription } from "../../context/SubscriptionContext";
import useAccessToken from "../../hooks/useAccessToken";
import { notification } from "../../helper";
import instance from "../../api";
import { TbBriefcase2 } from "react-icons/tb";
import { useInox } from "../../context/InboxContext";

const MainSider = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [newOrgModal, setNewOrgModal] = useState(false);
  const { orgDetails, currentOrg, setCurrentOrg, getTokens, setLoading } = useSubscription();
  const fetchAccessToken = useAccessToken();
  const [orgName, setOrgName] = useState("");
  const { newMessages } = useInox();

  const orgs = (
    <div className="flex flex-col items-start">
      {user?.organization_data?.map((org) => (
        <button key={org.id} onClick={() => {setCurrentOrg(org.id); localStorage.setItem("org", org.id)}} className={`hover:bg-blue/50 w-full p-1 rounded-md text-left ${org.id === currentOrg && "bg-blue/50"} flex justify-between items-center gap-2`}>
          {org.name}
          {org.id === currentOrg && <FaCheck className="text-blue/500" />}
        </button>
      ))}
      <div className="border-b border-[#D5D6E2] w-full my-1"></div>
      <button className="flex items-center gap-2 hover:bg-blue/50 p-1 rounded-md" onClick={() => setNewOrgModal(true)}> <FaPlus /> Add New Organisation</button>
    </div>
  );

  const submit = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post(`/organizations`, {
        name: orgName,
        user: user.sub
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "This is submit res");
        setNewOrgModal(false);
        setOrgName("");
        notification.success("Organization created successfully!");
        getTokens();
        navigate("/");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error(err?.response?.data?.error);
      });
  };

  return (
    <Sider
      className="pt-5"
      style={{ backgroundColor: "#16171D" }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      {!collapsed ? (
        <div className="flex justify-between items-center pl-7 pr-5">
          <h1 className="text-white text-lg font-semibold">Botstreams</h1>
          <button onClick={() => setCollapsed(true)}>
            <LuPanelLeftClose className="sider-icon" />
          </button>
        </div>
      ) : (
        <button
          className="flex justify-center w-full"
          onClick={() => setCollapsed(false)}
        >
          <LuPanelLeftOpen className="sider-icon" />
        </button>
      )}
      <div className="border-b border-gray/800 my-5"></div>
      <Popover content={orgs} placement="bottomLeft" arrow={false} title="Organizations" trigger="hover">
        <button className={`text-[#F6F7F9] text-sm text-left font-semibold rounded-md bg-gray/900 ml-4 p-2 border border-gray/600 flex  items-center ${collapsed ? "justify-center w-[60%]" : "justify-between w-[84%]"}`}>
          <div className="flex items-center gap-2 truncate">
            <TbBriefcase2 className="text-lg shrink-0" />
            <p className="truncate">{!collapsed && orgDetails?.name}</p>
          </div>
          {!collapsed && <FaCaretDown className="text-xs" />}
        </button>
      </Popover>
      <div className="border-b border-gray/800 mt-5"></div>
      <Menu
        className="mt-5"
        style={{ backgroundColor: "#16171D" }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        items={[
          // {
          //   key: "/create",
          //   icon: <FaPlus className="sider-icon" />,
          //   label: "Create Bot",
          //   onClick: () => navigate("/create"),
          // },
          {
            key: "/",
            icon: <LuFolders className="sider-icon" />,
            label: "My Bots",
            onClick: () => navigate("/"),
          },
          // {
          //   key: "/integrations",
          //   icon: <IoExtensionPuzzleOutline />,
          //   label: "Integrations",
          // },
          // {
          //   key: "/settings",
          //   icon: <IoSettingsOutline />,
          //   label: "Settings",
          // },
          {
            key: "/settings",
            icon: <IoSettingsOutline />,
            label: "Settings",
            onClick: () => navigate("/settings"),
          },
          {
            key: "/help",
            icon: <MdOutlineSupport />,
            label: "Documentation",
          },
          {
            key: "/teams",
            icon: <AiOutlineTeam />,
            label: "Teams",
            onClick: () => navigate("/teams"),
          },
          // {
          //   key: "/models",
          //   // icon: <AiOutlineTeam />,
          //   label: "Models",
          //   onClick: () => navigate("/models"),
          // },
          // {
          //   key: "/vector-stores",
          //   // icon: <AiOutlineTeam />,
          //   label: "Vector Stores",
          //   onClick: () => navigate("/vector-stores"),
          // },
          user?.roles?.includes("superadmin") && {
            key: "/plugins",
            icon: <IoExtensionPuzzleOutline />,
            label: "Plugins Manager",
            onClick: () => navigate("/plugins"),
          },
          {
            key: "/inbox",
            icon: <MdOutlineChat />,
            label: <div className="flex justify-between items-center">
              Inbox
              {newMessages.length > 0 && !location.pathname.includes("/inbox") && <div className="bg-error/500 size-3 rounded-full"></div>}
            </div>,
            onClick: () => navigate("/inbox"),
          },
          // {
          //   key: "/playground",
          //   icon: <FaCodeBranch />,
          //   label: "Playground",
          //   onClick: () => navigate("/playground"),
          // },
          user?.roles?.includes("superadmin") && {
            key: "/whatsapp-numbers",
            icon: <FaWhatsapp />,
            label: "WhatsApp Numbers",
            onClick: () => navigate("/whatsapp-numbers"),
          },
        ]}
      />

      <Modal
        title="Create New Organisation"
        open={newOrgModal}
        footer={null}
        centered
        onCancel={() => setNewOrgModal(false)}
      >
        <div>
          <input type="text" placeholder="Enter your organisation name" className="input w-full" value={orgName} onChange={(e) => setOrgName(e.target.value)} />
          <button className="btn mt-3 w-full" onClick={() => submit()}>
            Create
          </button>
        </div>
      </Modal>
    </Sider>
  );
};

export default MainSider;
