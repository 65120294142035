import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainSider from "../sidebar";
import MainHeader from "../header";
import { useSubscription } from "../../context/SubscriptionContext";

const MainLayout = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orgDetails } = useSubscription();

  return (
    <Layout className="min-h-screen">
      {!location.pathname.includes("/templates") && !location.pathname.includes("/pricing") && !location.pathname.includes("/order-confirm") && <MainSider user={user} />}
      <Layout>
        <MainHeader user={user} />
        {(orgDetails?.free_trial === 'expired' && orgDetails?.free_trial !== 'active') && <div className="bg-[#FFB524] p-2 px-6 flex justify-center items-center">
          <h2 className="text-gray/950 font-medium">Your free trial has expired. Please <button className="underline" onClick={() => navigate('/pricing')}>upgrade</button> to enjoy all the premium benefits.</h2>
        </div>}
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
