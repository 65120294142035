import React, { useEffect, useState } from "react";
import instance from "../../api";
import { notification } from "../../helper";
import { Form, Input } from "antd";
import { FiEye } from "react-icons/fi";

const WhatsAppNumbers = () => {
  const [form] = Form.useForm();
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getAllNumbers();
  }, []);

  const getAllNumbers = () => {
    instance
      .get(`/numbers`)
      .then((res) => {
        setNumbers(res.data.data);
        console.log(res, "This is numbbers");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const addNumber = (val) => {
    instance
      .post(`/numbers`, val)
      .then((res) => {
        notification.success("Number added successfully");  
        getAllNumbers();
        form.resetFields(); 
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const unlinkNumber = (id) => {
    instance
      .post(`/numbers/unlink/${id}`)
      .then((res) => {
        notification.success("Number unlinked successfully");  
        getAllNumbers();
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  return (
    <div className="m-5 space-y-3">
      <div className="bg-white p-5 rounded-lg shadow-md space-y-3">
        <h2 className="label">Add Number</h2>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={addNumber}
          className="flex gap-3"
        >
          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: "Please input a number",
              },
            ]}
          >
            <Input
              className="input"
              placeholder="Enter a number"
            />
          </Form.Item>
          <button className="btn h-fit mt-0.5">Add Number</button>
        </Form>
      </div>
      <div className="bg-white p-5 rounded-lg shadow-md space-y-3">
        <h2 className="label">Available Numbers</h2>
        <div className="ml-3 space-y-8">
          {numbers.map((item) => (
            <div key={item._id} className="flex items-center gap-6 font-medium text-gray/950">
              <p>{item.number}</p>
              {item.isLinked && <button className="btn" onClick={() => unlinkNumber(item._id)}>Unlink</button>}
              {item.isLinked && <a href={`${process.env.REACT_APP_CHAT_FE_URL}/${item.bot_id}`} target="_blank" rel="noreferrer" className="btn-inverse"><FiEye /> Preview</a>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppNumbers;
