import React, { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { LuFolders, LuPanelLeftClose, LuPanelLeftOpen } from "react-icons/lu";
import { Menu } from "antd";
import { IoChevronBack, IoColorPaletteOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TbSettings } from "react-icons/tb";
import { HiOutlineShare } from "react-icons/hi";
import { FaCodeBranch } from "react-icons/fa6";
import { CgPlug } from "react-icons/cg";
import { VscJson } from "react-icons/vsc";
import { MdOutlineChat } from "react-icons/md";

const BotSider = ({ user, current, setKey }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { json } = location.state || false;
  const { botId } = useParams();

  return (
    <Sider
      className="pt-5"
      style={{ backgroundColor: "#16171D" }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      {!collapsed ? (
        <div className="flex justify-between items-center pl-7 pr-5">
          <button className="text-white flex items-center gap-2" onClick={() => navigate("/")}>
            <IoChevronBack className="text-lg" />
            <h1 className="text-lg font-semibold">Back</h1>
          </button>
          <button onClick={() => setCollapsed(true)}>
            <LuPanelLeftClose className="sider-icon" />
          </button>
        </div>
      ) : (
        <button
          className="flex justify-center w-full"
          onClick={() => setCollapsed(false)}
        >
          <LuPanelLeftOpen className="sider-icon" />
        </button>
      )}
      <div className="border-b border-gray/800 mt-5"></div>
      <Menu
        className="mt-5"
        style={{ backgroundColor: "#16171D" }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[current]}
        selectedKeys={[current]}
        items={[
          {
            key: "setup",
            icon: <TbSettings className="sider-icon" />,
            label: "Setup",
            onClick: () => setKey("setup"),
            disabled: json,
          },
          {
            key: "data",
            icon: <LuFolders className="sider-icon" />,
            label: "Data",
            onClick: () => setKey("data"),
            disabled: !botId,
          },
          {
            key: "share",
            icon: <HiOutlineShare className="sider-icon" />,
            label: "Share",
            onClick: () => setKey("share"),
            disabled: !botId,
          },
          {
            key: "themes",
            icon: <IoColorPaletteOutline className="sider-icon" />,
            label: "Themes",
            onClick: () => setKey("themes"),
            disabled: !botId,
          },
          // {
          //   key: "conversations",
          //   icon: <MdOutlineChat className="sider-icon" />,
          //   label: "Conversations",
          //   onClick: () => setKey("conversations"),
          //   disabled: !botId,
          // },
          // {
          //   key: "plugins",
          //   icon: <CgPlug className="sider-icon" />,
          //   label: "Plugins",
          //   onClick: () => setKey("plugins"),
          //   disabled: !botId,
          // },
          // {
          //   type: 'divider',
          // },
          // {
          //   key: "code",
          //   icon: <VscJson className="sider-icon" />,
          //   label: "Json",
          //   onClick: () => setKey("code"),
          //   disabled: !botId && !json,
          // },
        ]}
      />
    </Sider>
  );
};

export default BotSider;