import React, { useEffect, useState } from "react";
import { Radio, Select, Switch, Tooltip } from "antd";
import { FaAngleDown, FaWhatsapp } from "react-icons/fa6";
import { IoCopyOutline, IoLink } from "react-icons/io5";
import { LuCode2 } from "react-icons/lu";
import { useParams } from "react-router-dom";
import instance from "../../../../api";
import { CopyToClipboard, notification } from "../../../../helper";
import useAccessToken from "../../../../hooks/useAccessToken";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useSubscription } from "../../../../context/SubscriptionContext";
import { CiLock } from "react-icons/ci";
const { Option } = Select;

const Share = ({ user, code, setCode, setLoading, getBot }) => {
  const { botId } = useParams();
  const [members, setMembers] = useState([]);
  const [invited, setInvited] = useState([]);
  const [email, setEmail] = useState("");
  const [inviteRole, setInviteRole] = useState('viewer');
  const iframeCode = `<iframe src="${process.env.REACT_APP_CHAT_FE_URL}/${botId}" frameborder="0" width="500px" height="600px"></iframe>`;
  const fetchAccessToken = useAccessToken();
  const [numbers, setNumbers] = useState([]);
  const [share, setShare] = useState({ isLinked: false, number: null });
  const [notifyAdmins, setNotifyAdmins] = useState(JSON.parse(code).send_notification_mail === true ? true : false);
  const { orgDetails } = useSubscription();

  useEffect(() => {
    getMembers();
    getMetadata();
    getAllNumbers();
    getLinkedNumber();
  }, []);

  const getMembers = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/view-members", { bot_id: botId }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is members");
        setMembers(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const getMetadata = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .get(`/bot/metadata/${botId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is metadata");
        setInvited(res?.data?.data?.invited);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const inviteMember = async () => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/invite", { email, role: inviteRole, bot_id: botId }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is invite");
        setLoading(false);
        notification.success("Invite sent successfully");
        getMetadata();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const assignRole = async (role, id) => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/assign-role", {
        user_id: id,
        bot_id: botId,
        role: role,
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "This is assign role");
        setLoading(false);
        notification.success("Role Assigned Successfully");
        getMembers();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const removeRole = async (role, id) => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/remove-role", {
        user_id: id,
        bot_id: botId,
        role: role,
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "This is remove role");
        setLoading(false);
        notification.success("Role Removed Successfully");
        getMembers();
        getMetadata();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const handleRoleChange = (e, id, prevRole) => {
    if (e === "editor") {
      assignRole(e, id);
    } else if (e === "viewer") {
      assignRole(e, id);
    } else if (e === "remove") {
      removeRole(prevRole, id);
    }
  };

  const determineRole = (organizationRoles, botId) => {
    for (const roleObj of organizationRoles) {
      if (roleObj.name === `view:bot:${botId}`) {
        return 'viewer';
      }
      if (roleObj.name === `edit:bot:${botId}`) {
        return 'editor';
      }
    }
    return 'None'; // or 'none' if you prefer to explicitly state no role
  };

  const handleStatusChange = (e) => {
    const codeObject = JSON.parse(code);
    if(e.target.value === 'public'){
      codeObject.is_public = true;
    }else{
      codeObject.is_public = false;
    }
    updateBot(codeObject);
  }

  const handleNotifyAdminsChange = (e) => {
    const codeObject = JSON.parse(code);
    if(e){
      codeObject.send_notification_mail = true;
      setNotifyAdmins(true);
    }else{
      codeObject.send_notification_mail = false;
      setNotifyAdmins(false);
    }
    updateBot(codeObject);
  }

  const updateBot = async (data) => {
    setLoading(true);
    try {
      const response = await instance.put(`/bot/update/${botId}`, { ...data });
      console.log(response);
      setLoading(false);
      getBot();
      notification.success("Bot updated successfully!");
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error(err?.response?.data?.message?.error);
    }
  };

  const getAllNumbers = () => {
    instance
      .get(`/numbers`)
      .then((res) => {
        console.log(res, "This is numbbers");
        setNumbers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const getLinkedNumber = () => {
    instance
      .get(`/numbers/linked/${botId}`)
      .then((res) => {
        console.log(res, "This is linked numbbers");
        setShare({ id: res?.data?.data?._id, isLinked: res?.data?.data?.isLinked, number: res?.data?.data?.number });
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const linkNumber = () => {
    instance
      .post(`/numbers/link/${share?.number}/${botId}`)
      .then((res) => {
        console.log(res, "This is linked response");
        notification.success("Number linked successfully"); 
        getLinkedNumber();
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const unlinkNumber = () => {
    console.log(share, "This is share");
    instance
      .post(`/numbers/unlink/${share?.id}`)
      .then((res) => {
        notification.success("Number unlinked successfully"); 
        setShare({ isLinked: false, number: null });
        getAllNumbers();
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  const handleSubmit = () => {
    if(share?.isLinked && share?.number) linkNumber();
    else if(share?.isLinked === false && share?.number) unlinkNumber();
    else if(share?.isLinked === true || share?.isLinked === false && !share?.number) {
      notification.error("Please select a number to link");
    }
  }

  return (
    <div className="m-8 space-y-8">
      <div className="bot-box space-y-4">
        <h1>Share</h1>
        <div className="flex justify-between items-center">
          <Radio.Group defaultValue={JSON.parse(code).is_public ? 'public' : 'restricted'} onChange={handleStatusChange}>
            <Radio value='public'><h3 className="text-base font-normal text-gray/950">Public access</h3></Radio>
            <Radio value='restricted'><h3 className="text-base font-normal text-gray/950">Restricted access</h3></Radio>
          </Radio.Group>
          <CopyToClipboard textToCopy={`${process.env.REACT_APP_CHAT_FE_URL}/${botId}`}>
            <div className="text-base font-semibold text-blue/500 flex items-center gap-2">
              <IoLink className="text-xl" />
              Copy link
            </div>
          </CopyToClipboard>
        </div>
        <div className="flex items-center gap-6">
          <div className="input flex justify-between items-center gap-4 w-full py-3 rounded-lg">
            <input
              type="email"
              className="w-full focus:outline-none"
              placeholder="johndoe@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <Select
              variant="borderless"
              className="w-[115px] shrink-0"
              placement="bottomRight"
              popupMatchSelectWidth={false}
              suffixIcon={<FaAngleDown className="text-gray/950 text-base" />}
              value={inviteRole}
              onChange={(value) => setInviteRole(value)}
              options={[
                {
                  label: "Can View",
                  value: "viewer",
                  desc: "Can only view chatbot but cannot edit",
                },
                {
                  label: "Can Edit",
                  value: "editor",
                  desc: "Can access admin panel and edit this bot",
                },
              ]}
              optionRender={(option) => (
                <div>
                  <h2 className={`text-base font-semibold text-gray/950`} >
                    {option.data.label}
                  </h2>
                  <p className="text-sm text-gray/500">{option.data.desc}</p>
                </div>
              )}
            /> */}
          </div>
          <button className="btn p-3 px-8 rounded-lg" onClick={inviteMember}>
            Invite
          </button>
        </div>
        <div className="font-normal">
          <h2 className="text-gray/950 text-base font-semibold">People with access:</h2>
          <div className="space-y-2 mt-2">
            {members
              ?.filter((item) => item.id !== user?.sub)
              ?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="flex justify-between items-center gap-4"
                  >
                    <h2>{item?.primaryEmail}</h2>
                    <button className="text-error/500 text-base font-medium" onClick={() => removeRole('viewer', item?.id)}>
                      Remove
                    </button>
                  </div>
                );
              })}
            {invited?.map((item) => {
                return (
                  <div
                    key={item}
                    className="flex justify-between items-center gap-4"
                  >
                    <h2>{item}</h2>
                    <h2 className="text-gray/500 font-medium">Pending</h2>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="bot-box space-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <LuCode2 className="text-2xl text-gray/500" />
            <h1>Embed Code</h1>
          </div>
          <CopyToClipboard textToCopy={iframeCode}>
            <div className="btn-2 p-2.5 shrink-0">
              <IoCopyOutline className="text-xl" />
            </div>
          </CopyToClipboard>
        </div>
        <textarea disabled className="input w-full text-[#888FA8] font-medium" value={`<iframe src="${process.env.REACT_APP_CHAT_FE_URL}/${botId}" frameborder="0" width="500px" height="600px"></iframe>`}></textarea>
        <div>
          <h3 className="text-gray/950 text-base font-semibold">How to use:</h3>
          <p className="font-normal text-gray/950 text-sm mt-1">
            Navigate to your page and ensure you are in editing mode. Locate the
            spot where you want the embedded content, use the HTML editor or
            Embed Code option, and paste the copied embed code into the editor.
          </p>
        </div>
      </div>

      <div className="bot-box space-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <IoMdNotificationsOutline className="text-2xl text-gray/500" />
            <h1>Notify Admins?</h1>
          </div>
          <Switch
            checked={notifyAdmins}
            onChange={(e) => handleNotifyAdminsChange(e)}
          />
        </div>
      </div>

      <div className={`bot-box space-y-4`}>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <FaWhatsapp className="text-2xl text-gray/500" />
            <h1>Whatsapp</h1>
          </div>
          {orgDetails?.whatsapp_support ?
           <Switch
            checked={share?.isLinked}
            onChange={(e) => setShare({ ...share, isLinked: e })}
          />
          :
          <p className="flex items-center gap-1 font-normal">
            <CiLock className="text-xl text-gray/500" />
            <button className="text-blue/500" onClick={() => window.location.href = 'mailto:umesh@spirinova.dev'}>Contact us</button> to activate this feature.
          </p>
          }
        </div>
        {share?.isLinked && orgDetails?.whatsapp_support && (
        <div className="input py-1 px-1 w-full">
          <Select
            variant="borderless"
            className="w-full"
            placeholder="Select a number to link"
            value={share?.number}
            onChange={(e) => setShare({ ...share, number: e })}
            suffixIcon={<FaAngleDown className="text-gray/950" />}
          >
            {numbers &&
              numbers
                .filter((item) => !item.isLinked)
                .map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.number}
                  </Option>
                ))}
            </Select>
          </div>
        )}
        {orgDetails?.whatsapp_support && <div className="flex justify-end">
          <button className="btn" onClick={handleSubmit}>
            Save
          </button>
        </div>}
      </div>

      {/* <div className="bot-box bg-transparent text-right p-0">
        <button className="btn text-sm">
          Save
        </button>
      </div> */}
    </div>
  );
};

export default Share;
