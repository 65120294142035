import React, { useEffect } from 'react'
import order from "../../assets/order.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { useSubscription } from '../../context/SubscriptionContext';

const OrderConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentOrg, setCurrentOrg, getOrgById } = useSubscription();

  const getParamsFromUrl = (search) => {
    const params = new URLSearchParams(search);

    return {
      // order_id: params.get('order_id'),
      amount: params.get('amount'),
      tax_amount: params.get('tax_amount'),
      charge_at: params.get('charge_at'),
      name: params.get('name'),
      org: params.get('org'),
    };
  };

  const params = getParamsFromUrl(location.search);
  
  useEffect(() => {
    setCurrentOrg(params?.org)
    localStorage.setItem("org", params?.org)
    getOrgById(params?.org)
  }, [params.org])

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <div className="m-5 h-[calc(100vh-10rem)] flex items-center justify-center gap-24 text-gray/950">
        <div className='flex flex-col items-start gap-4'>
            <img src={order} alt="order" />
            <div>
                <h2 className='grotesk text-2xl font-semibold'>Thank you for your order</h2>
                {/* <h3 className='text-base font-medium mt-1'>Order id {params?.order_id}</h3> */}
            </div>
            <h3 className='text-base'>Congratulations, your online payment has <br /> been successfully processed.</h3>
            <button className='btn py-4 px-8 font-medium mt-3' onClick={() => {navigate('/'); setCurrentOrg(params?.org); localStorage.setItem("org", params?.org)}}>
                Back to Home
            </button>
        </div>

        <div className='w-[450px]'>
            <div className='bg-blue/900 p-4 rounded-t-lg text-white flex items-center justify-between'>
                <div>
                    <h2 className='text-xl font-semibold'>{params?.name} Plan</h2>
                    {/* <h3 className='text-base font-normal mt-1 text-[#C4AFF9]'>Next payment on {formatDate(params?.charge_at)}</h3> */}
                </div>
                <h2 className='text-lg font-semibold'>${params?.amount / 100}/month</h2>
            </div>
            <div className='bg-white p-4 rounded-b-lg'>
                <div className='text-base flex items-center justify-between'>
                    <h2 className='font-medium'>Subtotal</h2>
                    <h2 className='font-semibold'>${params?.amount / 100}</h2>
                </div>
                <div className='text-base flex items-center justify-between mt-1'>
                    <h2 className='font-medium'>Tax</h2>
                    <h2 className='font-semibold'>${params?.tax_amount / 100}</h2>
                </div>
                <div className='border-t border-gray/200 mt-6 mb-4'></div>
                <div className='text-2xl font-semibold flex items-center justify-between mt-1'>
                    <h2>Total</h2>
                    <h2>${params?.amount / 100}</h2>
                </div>
                <div className='text-base text-success/500 mt-4 text-center bg-success/50 p-2 rounded-md'>
                    Your subscription is now active
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrderConfirm