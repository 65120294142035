import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../api";
import { BoxLoader, notification } from "../../helper";
import template1 from "../../assets/Template 1.png";
import template2 from "../../assets/Template 2.png";
import template3 from "../../assets/Template 3.png";
import template4 from "../../assets/Template 4.png";
import template5 from "../../assets/Template 5.png";
import template6 from "../../assets/Template 6.png";
import { FaPlus } from "react-icons/fa6";
import { useSubscription } from "../../context/SubscriptionContext";

const Templates = ({ user }) => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const { canCreateBot, currentOrg } = useSubscription();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();

  const templateImages = [template2, template3, template4, template5, template6];

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    // setLoading(true);
    instance
      .get(`/templates`)
      .then((res) => {
        console.log(res, "res");
        setTemplates(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const createBot = (data) => {
    if (!canCreateBot()) return;
    setLoading(true);
    setCurrent(0);
    instance
      .post("/bot/create", {
        ...data,
        org_id: currentOrg,
        created_by: user?.email
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        notification.success("Bot created successfully!");
        handleGenerate(res.data.data._id);
      })
      .catch((err) => {
        console.log(err, "err");
        notification.error(err?.response?.data?.message?.error);
        setLoading(false);
      });
  };

  const handleGenerate = (botId) => {
    setLoading(true);
    setCurrent(1);
    instance
      .post(`/api/generate`, { botId })
      .then((res) => {
        // console.log(res, "THis is generate");
        setLoading(false);
        notification.success("Bot generated successfully!");
        navigate(`/bot/edit/${botId}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  return (
    <div className="m-5">
      <div className="flex items-center gap-2">
        <h1 className="text-xl font-semibold text-gray/950 grotesk">All templates</h1>
      </div>
      <div className="mt-6 flex items-start gap-6 flex-wrap">
        {templates.map((item, index) => {
          return(
            <button key={item._id} className="relative mb-10 group" onClick={() => createBot(item)}>
              <img src={templateImages[index % templateImages.length]} alt={`Template ${index + 1}`} className="group-hover:hidden" />
              <img src={template1} alt={`Template 1`} className="hidden group-hover:block" />
              <h2 className="text-white w-fit flex items-center gap-2 text-base font-bold mx-auto absolute left-0 right-0 top-3 opacity-0 group-hover:opacity-100 transition-opacity">
                <FaPlus /> 
                Use this template
              </h2>
              <div className="bg-white w-4/5 h-32 flex flex-col justify-center items-center gap-3 mx-auto rounded-lg absolute left-0 right-0 top-12 group-hover:shadow">
                <h1 className="text-gray/950 grotesk text-xl font-semibold leading-tight w-[70%]">
                  {item.name}
                </h1>
                <h2 className="text-gray/500 text-sm">
                  {item.temp_desc}
                </h2>
              </div>
            </button>
          )
        })}
      </div>
      <BoxLoader loading={loading} current={current} />
    </div>
  );
};

export default Templates;
