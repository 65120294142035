import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import BotHeader from "../header";
import BotSider from "../sidebar";
import Create from "../../pages/create";
import instance from "../../../../api";
import Setup from "../../pages/setup";
import Share from "../../pages/share";
import Data from "../../pages/add-data";
import Themes from "../../pages/themes";
import Plugins from "../../pages/plugins";
import useAccessToken from "../../../../hooks/useAccessToken";
import Conversations from "../../pages/conversations";

const BotLayout = ({ user, setLoading }) => {
  const location = useLocation();
  const { json } = location.state || false;
  const [key, setKey] = useState(json ? "code" : "setup");
  const { botId } = useParams();
  const { template } = useParams();
  const fetchAccessToken = useAccessToken();
  const [plugins, setPlugins] = useState(["file"]);
  const initialJsonTemplate = {
    name: "",
    avatar: "https://storage.googleapis.com/botstreams-bucket/public/avatar.jpg",
    providers: [
      {
        type: "plugin",
        plugin_name: "avatar_langchain_plugin",
      },
    ],
    model_config: {
      model: "",
      temperature: 0,
      top_p: 0,
      max_tokens: 4000,
      send_memory: false,
    },
    theme: "default",
    is_public: false,
    should_generate_audio: false,
    instructions: [
      {
        role: "system",
        content: "",
      },
    ],
    pre_defined_questions: [],
    created_by: user?.email
  };

  const [code, setCode] = useState(JSON.stringify(initialJsonTemplate, null, 2));

  useEffect(() => {
    if (botId) {
      getBot();
    }
  }, [botId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const coversation = params.get("coversation");
    if (coversation === "true") {
      setKey("conversations");
    }
  }, [])
  
  const getBot = async () => {
    setLoading(true);
    const token = await fetchAccessToken();
    instance
      .get(`/bot/${botId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setCode(JSON.stringify(res.data.data, null, 2));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
        if (error.response.data.statusCode === 403) {
          // setForbidden(true)
        }
      });
  };

  return (
    <Layout className="min-h-screen">
      {!botId || !json && <BotSider user={user} current={key} setKey={setKey} />}
      <Layout>
        <BotHeader user={user} current={key} setKey={setKey} code={code} />
        <Content>
          {key === 'setup' && <Setup user={user} code={code} setCode={setCode} setLoading={setLoading} getBot={getBot} setKey={setKey} />}
          {key === 'data' && <Data user={user} code={code} setCode={setCode} setLoading={setLoading} getBot={getBot} plugins={plugins} setPlugins={setPlugins} />}
          {key === 'share' && <Share user={user} code={code} setCode={setCode} setLoading={setLoading} getBot={getBot} />}
          {key === 'themes' && <Themes user={user} code={code} setCode={setCode} setLoading={setLoading} getBot={getBot} />}
          {key === 'code' && <Create user={user} code={code} setCode={setCode} setLoading={setLoading} json={json} />}
          {key === 'plugins' && <Plugins user={user} code={code} setCode={setCode} setLoading={setLoading} />}
          {/* {key === 'conversations' && <Conversations user={user} code={code} setCode={setCode} setLoading={setLoading} />} */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BotLayout;
