import React, { useEffect, useRef } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { ScrollArea } from "../bot/components/scroll-area";
import avatar from "../../assets/Avatar Bot.png";
import { Switch } from "antd";
import { useSubscription } from "../../context/SubscriptionContext";

const ChatArea = ({ openDetails, setOpenDetails, chats, saveChat, message, setMessage, switchManual }) => {
  const scrollAreaRef = useRef(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [chats]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Options for formatting the date
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Format the time
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedTime = `${hours % 12 || 12}:${String(minutes).padStart(
      2,
      "0"
    )}${ampm}`;

    return `${formattedDate}, ${formattedTime}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div
      className={`${
        openDetails ? "w-2/3" : "w-full"
      } pb-0 space-y-4 h-[calc(100vh-270px)]`}
    >
      <div className="p-2 px-5 bg-white border-b border-gray/200 drop-shadow-sm flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src={
              chats?.avatar
                ? chats?.avatar
                : `https://ui-avatars.com/api/?name=${
                    chats?.email ? chats?.email : chats?.user_id
                  }&bold=true&background=random`
            }
            alt=""
            className="size-9 rounded-full border"
          />
          <div>
            <h2 className="text-gray/950 text-sm font-medium">
              {chats?.email}
            </h2>
            <h3 className="text-sm text-gray/500">
              last updated{" "}
              {formatTimestamp(
                chats?.session[chats?.session?.length - 1]?.createdAt
              )}
            </h3>
          </div>
        </div>
        <button onClick={() => setOpenDetails(true)}>
          <HiOutlineInformationCircle className="text-2xl" />
        </button>
      </div>

      <div className="flex flex-col relative h-full px-5">
        <ScrollArea
          ref={scrollAreaRef}
          className="flex-1 overflow-auto overflow-x-hidden relative overscroll-none"
        >
          {chats?.session?.map((item) => {
            return (
              <div key={item?.id}>
                {item?.role === "user" ? (
                  <div className="flex items-end gap-4 mt-2">
                    <img
                      className="size-10 mb-6 rounded-full cursor-pointer"
                      src={
                        chats?.avatar
                          ? chats?.avatar
                          : `https://ui-avatars.com/api/?name=${
                              chats?.email ? chats.email : chats.user_id
                            }&bold=true&background=random`
                      }
                      alt=""
                    />
                    <div className="space-y-2">
                      <h2 className="text-sm font-semibold text-gray/950">
                        {chats?.email}
                      </h2>
                      <h2 className="text-sm font-medium text-gray/950 p-2.5 bg-white rounded-lg w-fit">
                        {item?.content}
                      </h2>
                      <h3 className="text-xs font-medium text-gray/500">
                        {formatDate(item?.createdAt)}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end items-end gap-4 mt-2">
                    <div className="space-y-2">
                      <h2 className="text-sm font-semibold text-gray/950 text-right">
                        {item?.sender ? item?.sender : chats?.bot_name}
                      </h2>
                      <h2 className="text-sm font-medium text-gray/950 p-2.5 bg-white rounded-lg w-fit">
                        {item?.content}
                      </h2>
                      <h3 className="text-xs font-medium text-gray/500 text-right">
                        {formatDate(item?.createdAt)}
                      </h3>
                    </div>
                    <img
                      className="size-10 mb-6 rounded-full cursor-pointer"
                      src={avatar}
                      alt="bot"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </ScrollArea>
      </div>

      <form
        className="bg-white m-5 rounded-lg p-4 drop-shadow-sm"
        onSubmit={saveChat}
      >
        <div className="flex items-center gap-2">
          <Switch checked={chats?.manual} onChange={(check) => switchManual(check)} />
          Switch to Manual
        </div>
        <div className="border-b border-gray/100 my-3"></div>
        <div className="flex items-center gap-2">
          <input
            type="text"
            disabled={!chats?.manual}
            className="w-full focus:outline-none text-gray/950"
            placeholder="Write your response"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                saveChat(e);
              }
            }}
          />
          <button className="text-gray/300 font-bold hover:text-gray/950">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatArea;
