import React, { useEffect, useState } from "react";
import instance from "../../api";
import { notification } from "../../helper";
import useAccessToken from "../../hooks/useAccessToken";
import { useSubscription } from "../../context/SubscriptionContext";
import message from "../../assets/message.png";

const Teams = ({ user, setLoading }) => {
  const [email, setEmail] = useState('');
  const [pendingMembers, setPendingMembers] = useState([]);
  const fetchAccessToken = useAccessToken();
  const { canInviteMember, members, setMembers, currentOrg } = useSubscription();

  useEffect(() => {
    getMembers();
    getInvitedMembers();
  }, [currentOrg]);

  const getMembers = async () => {
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/org-members", { org_id: currentOrg }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is members");
        setMembers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const getInvitedMembers = async () => {
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/invitations", { org_id: currentOrg }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is invitations");
        setPendingMembers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const inviteMember = async () => {
    if (!canInviteMember()) return;
    
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/invite", { email, role: 'admin', org_id: currentOrg }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis is invite");
        setLoading(false);
        setEmail('');
        getInvitedMembers();
        notification.success("Invite sent successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const deleteInvite = async (id) => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post(`/organizations/invite/delete/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "THis delete is invite");
        setLoading(false);
        getInvitedMembers();
        notification.success("Invite deleted successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const removeRole = async (role, id) => {
    setLoading(true);
    const token = await fetchAccessToken(); 
    instance
      .post("/organizations/remove-role", {
        user_id: id,
        org_id: currentOrg,
        role: role,
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "This is remove role");
        setLoading(false);
        notification.success("Removed from Organization Successfully");
        getMembers();
        getInvitedMembers();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  return (
    <div className="m-5 bg-white p-5 rounded-lg">
      <div>
        <h2 className="label mb-3">Invite a member</h2>
        <input
          className="input w-1/5"
          type="email"
          placeholder="johndoe@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="btn ml-4" onClick={inviteMember}>Invite</button>
      </div>
      <div className="mt-10">
        <h2 className="label">Invited members</h2>
        {members.length > 0 || pendingMembers.length > 0 ? 
        <div className="mt-3 px-">
          <div className="flex items-center justify-between px-3">
            <h2 className="text-gray/500 text-base font-normal">Email</h2>
            <h2 className="text-gray/500 text-base font-normal">Actions</h2>
          </div>
            {members?.map((item) => (
                <div key={item?.id} className="space-x-3 flex justify-between items-center border border-gray/200 rounded-lg p-2 px-3 mt-2">
                    <div className="flex items-center gap-3">
                      <img className="size-10 rounded-full" src={item?.avatar ? item?.avatar : `https://ui-avatars.com/api/?name=${item?.primaryEmail}&bold=true&color=fff&background=cdbbfa`} alt="" />
                      <span className="text-base text-gray/950">{item?.primaryEmail}</span>
                    </div>
                    {item.id !== user?.sub && <button className="font-semibold text-error/500 text-base" onClick={() => removeRole('admin', item?.id)}>
                      Remove
                    </button>}
                </div>
            ))}
            {pendingMembers?.map((item) => (
                <div key={item?.id} className="space-x-3 flex justify-between items-center border border-gray/200 rounded-lg p-2 px-3 mt-2">
                    <div className="flex items-center gap-3">
                      <img className="size-10 rounded-full" src={item?.avatar ? item?.avatar : `https://ui-avatars.com/api/?name=${item?.invitee}&bold=true&color=fff&background=cdbbfa`} alt="" />
                      <span className="text-base text-gray/950">{item?.invitee}</span>
                    </div>
                    <button className="text-gray/500 text-base font-medium" onClick={() => deleteInvite(item?.id)}>
                      Delete Invite
                    </button>
                </div>
            ))}
        </div> :
        <div className="mt-3 flex flex-col justify-center items-center gap-3">
          <img src={message} alt="message" />
          <h2 className="text-gray/500 text-base font-medium">You have not yet invited anyone in your organization</h2>
        </div>
        }
      </div>
    </div>
  );
};

export default Teams;
