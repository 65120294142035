import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notification } from "../../helper";
import instance from "../../api";
import { Form, Input } from "antd";
import useAccessToken from "../../hooks/useAccessToken";
import { useSubscription } from "../../context/SubscriptionContext";

const MyForm = ({ user }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fetchAccessToken = useAccessToken();
  const { getTokens } = useSubscription();

  useEffect(() => {
    if(user?.name != null){
      form.setFieldsValue({name: user.name})
    }
  }, [user])

  const submit = async (data) => {
    updateName(data);
    const token = await fetchAccessToken(); 
    instance
      .post(`/organizations`, {
        name: data.organization,
        // domain: data.domain,
        user: user.sub
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res, "This is form res");
        notification.success("Data saved successfully!");
        getTokens();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };
  
  const updateName = async (data) => {
    const token = await fetchAccessToken(); 
    instance
      .post(`/auth/update-name`, {
        userId: user.sub,
        name: data.name
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {

      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  return (
    <div className="auth-wrapper">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        onFinish={(val) => submit(val)}
        className="auth-content"
      >
        <h1 className="text-center text-3xl font-semibold">
          Almost there......
        </h1>
        <Form.Item
          label={<h2>Name</h2>}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Input
            className="input min-w-full"
            placeholder="Enter your full name"
          />
        </Form.Item>
        <Form.Item
          label={<h2>Organization</h2>}
          name="organization"
          rules={[
            {
              required: true,
              message: "Please input your organization name",
            },
          ]}
        >
          <Input
            className="input min-w-full"
            placeholder="Enter your organization name"
          />
        </Form.Item>
        {/* <Form.Item
          label={<h2>Domain</h2>}
          name="domain"
          rules={[
            {
              required: true,
              message: "Please input your domain name",
            },
          ]}
        >
          <Input
            className="input min-w-full"
            placeholder="domain"
            prefix={<span className="text-base font-medium">{process.env.REACT_APP_CHAT_FE_URL}/#/chat/</span>}
          />
        </Form.Item> */}
        <button className="btn w-full">Submit</button>
      </Form>
    </div>
  );
};

export default MyForm;
