import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "../../helper";
import instance from "../../api";
import { FcGoogle } from "react-icons/fc";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Form, Input } from "antd";

const Singup = () => {
  const navigate = useNavigate();
  const [keyVisible, setKeyVisible] = useState(false);
  const [confirmKeyVisible, setConfirmKeyVisible] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const checkPassword = () => {
    if (data.password !== confirm) {
      notification.error("Password does not match!");
    } else {
      signup();
    }
  };

  const signup = (data) => {
    instance
      .post(`/auth/signup`, data)
      .then((res) => {
        console.log(res);
        notification.success("Account created successfully!");
        navigate("/form", { state: { accountId: res.data.data._id } });
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  return (
    <div className="auth-wrapper">
      <Form
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        onFinish={(val) => signup(val)}
        className="auth-content"
      >
        <h1 className="text-center text-3xl font-semibold">
          Welcome to Avatar
        </h1>
        <Form.Item
          label={<h2>Email Address</h2>}
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your email address!",
            },
          ]}
        >
          <Input
            className="input min-w-full"
            placeholder="johndoe@example.com"
          />
        </Form.Item>
        <Form.Item
          label={<h2>Password</h2>}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password className="input min-w-full" placeholder="Password" />
        </Form.Item>
        <Form.Item
          name=""
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password className="input min-w-full" placeholder="Re-Enter Password" />
        </Form.Item>
        <div></div>
        <button className="btn w-full">Sign Up</button>
        <h2 className="text-center">
          Already have an account?{" "}
          <button className="underline" onClick={() => navigate("/login")}>
            Log in
          </button>
        </h2>
        <h2 className="w-full text-center border-b leading-[0.1em]">
          <span className="bg-white px-6">Or</span>
        </h2>
        <button
          type="button"
          className="btn w-full bg-white text-gray/950 border flex justify-center items-center gap-2"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`)
          }
        >
          <FcGoogle className="text-xl" />
          Continue with Google
        </button>
      </Form>
    </div>
  );
};

export default Singup;
