import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { LogtoProvider, UserScope } from "@logto/react";
import { SubscriptionProvider } from "./context/SubscriptionContext";
import { InboxProvider } from "./context/InboxContext";

const config = {
  endpoint: process.env.REACT_APP_LOGTO_ENDPOINT,
  appId: process.env.REACT_APP_LOGTO_APP_ID,
  resources: [process.env.REACT_APP_BASE_URL],
  scopes: [
    UserScope.Organizations,
    UserScope.OrganizationRoles,
    UserScope.Profile,
    UserScope.Email,
    UserScope.Roles,
  ],
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LogtoProvider config={config}>
      <Router>
        <SubscriptionProvider>
          <InboxProvider>
            <App />
          </InboxProvider>
        </SubscriptionProvider>
      </Router>
    </LogtoProvider>
  </React.StrictMode>
);
