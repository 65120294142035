import { useLogto } from '@logto/react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const RequireAuth = () => {
  const { isAuthenticated, isLoading, signIn } = useLogto();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      if (location.pathname !== '/') {
        sessionStorage.setItem('redirectAfterLogin', location.pathname);
      }
      signIn(`${process.env.REACT_APP_CALLBACK_URL}/callback`);
    }
  }, [isAuthenticated, isLoading, signIn]);

  return isAuthenticated ? <Outlet /> : null;
};

export default RequireAuth;