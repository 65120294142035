import React, { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { TbRobotFace, TbUserHexagon } from "react-icons/tb";
import { ScrollArea } from "../bot/components/scroll-area";
import { useSubscription } from "../../context/SubscriptionContext";
import { Checkbox, Dropdown, Popover } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";

const ConvoList = ({ history, conversationId, setConversationId, setChats, selectedOption, setSelectedOption, newMessages, setNewMessages, userList, botList }) => {
  const { allBots } = useSubscription();
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    setChecked([])
  }, [selectedOption])

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInSeconds = Math.floor((now - date) / 1000); // Difference in seconds

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hr${hours !== 1 ? "s" : ""} ago`;
    } else {
      // Format as "DD MMM" for dates older than 24 hours
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      });
    }
  };

  const getBotName = (id) => {
    const bot = botList.find((item) => item.id === id);
    return bot ? bot.name : null; 
  };

  const items = [
    {
      label: <h2 className="flex justify-between items-center gap-2">All Bots {selectedOption === 'all' && <FaCheck className="text-blue/500" />}</h2>,
      key: 'all',
    },
    ...botList.map((bot) => ({
      label: <h2 className="flex justify-between items-center gap-2">{bot?.name} {selectedOption === bot?.id && <FaCheck className="text-blue/500" />}</h2>,
      key: bot?.id,
    })),
  ];

  const onClick = ({ key }) => {
    setSelectedOption(key)
  };

  const content = (
    <div>
      <Checkbox.Group
        className="flex flex-col gap-2"
        value={checked}
        onChange={(values) => setChecked(values)}
      >
        {userList?.map((item) => (
          <Checkbox key={item?.id} value={item?.id}>{item?.email ? item?.email : item?.id}</Checkbox>
        ))}
      </Checkbox.Group>
      <div className="border-b border-[#D5D6E2] my-3"></div>
      <button className="text-center w-full text-base text-blue/500" onClick={() => setChecked([])}>
        Reset Filter
      </button>
    </div>
  );

  return (
    <div className="lg:w-1/3 xl:w-1/5 shrink-0 border-r border-gray/200 bg-white">
      <div className="p-4 border-b border-gray/200 flex justify-between items-center inbox-select">
        <Dropdown
          menu={{
            items,
            onClick,
          }}
          trigger={['click']}
        >
          <h2 className="cursor-pointer text-sm text-gray/950 font-medium flex items-center gap-1.5">
            {selectedOption === 'all' ? "All Bots" : getBotName(selectedOption)}
            <IoIosArrowDown className="text-base" />
          </h2>
        </Dropdown>
        <div className="flex items-center gap-3">
          {/* <IoSearch className="text-xl" /> */}
          <Popover content={content} title="Filter by User" arrow={false}>
            <BsFilter className="text-2xl" />
          </Popover>
        </div>
      </div>
      <div className="px-4 border-b border-gray/200 space-x-4">
        <button className="py-2 text-xs text-gray/500 font-medium /border-b-4 /border-black">
          All ({history?.length})
        </button>
        <button className="py-2 text-xs text-gray/500 font-medium">
          Unread ({newMessages?.length})
        </button>
      </div>

      <div className="h-[calc(100vh-155px)]">
        <ScrollArea className="h-full">
          {history?.filter(item => checked.length === 0 || checked.includes(item.user_id)).map((item) => {
            const showDot = newMessages.some(msg => msg === item._id);
            return (
              <button key={item?._id} className={`p-4 border-b border-gray/200 w-full space-y-4 ${conversationId === item?._id && "bg-blue/50"}`} onClick={() => {
                setConversationId(item?._id);
                setChats(item);
                setNewMessages((prevMessages) => 
                  prevMessages.filter(msg => msg !== item._id)
                );
              }}>
                <div className="flex justify-between items-center">
                  <h3 className="text-gray/950 text-xs border border-[#30B0C7] bg-[#E6F5F8] p-1 rounded-md">
                    {item.bot_name}
                  </h3>
                  {item?.manual ? <TbUserHexagon className="text-gray/500 text-xl" /> :
                  <TbRobotFace className="text-gray/500 text-xl" />}
                </div>
                <div className="flex justify-between gap-2">
                  <div className="flex items-center text-left gap-2">
                    <img
                      src={
                        item?.avatar
                          ? item?.avatar
                          : `https://ui-avatars.com/api/?name=${
                              item?.email ? item.email : item.user_id
                            }&bold=true&background=random`
                      }
                      alt=""
                      className="size-8 rounded-full border shrink-0"
                    />
                    <div className="truncate shrink">
                      <h2 className="text-base text-gray/950 font-semibold truncate">
                        {item?.email ? item?.email : item?.user_id}
                      </h2>
                      <h3 className="text-sm text-gray/950 mt-1 truncate text-wrap">
                        {item?.summary}
                      </h3>
                    </div>
                  </div>
                  <div className="shrink-0 flex flex-col items-center gap-2.5">
                    <h3 className="text-gray/500 text-sm">
                      {formatTimestamp(
                        item.session[item?.session?.length - 1]?.createdAt
                      )}
                    </h3>
                    {item?._id !== conversationId && showDot && <div className="bg-error/500 size-4 rounded-full border border-white"></div>}
                  </div>
                </div>
                <h3 className="text-sm text-gray/500 text-left">
                  {item?.session[item?.session?.length - 1]?.content?.length > 45
                    ? item?.session[item?.session?.length - 1]?.content?.substring(
                        0,
                        45
                      ) + "..."
                    : item?.session[item?.session?.length - 1]?.content}
                </h3>
              </button>
            );
          })}
        </ScrollArea>
      </div>
    </div>
  );
};

export default ConvoList;
