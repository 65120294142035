import React from "react";
import { AiOutlineCode } from "react-icons/ai";
import { BsGraphUp, BsSoundwave } from "react-icons/bs";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { LuDatabase } from "react-icons/lu";
import { MdAddToDrive } from "react-icons/md";
import { SiOpenai, SiSwagger } from "react-icons/si";
import { SlGlobe } from "react-icons/sl";

const Plugins = () => {
  const plugins = [
    {
      name: "Coderun",
      description:
        "Coderun is a plugin that allows you to run code in your bot.",
      icon: <AiOutlineCode />,
    },
    {
      name: "SQL reader",
      description:
        "Reads and queries PostgreSQL databases directly from the chat.",
      icon: <LuDatabase />,
    },
    {
      name: "OpenAI TTS",
      description:
        "Converts GPT answers to speech using OpenAI's TTS service.",
      icon: <BsSoundwave />,
    },
    {
      name: "TTS",
      description:
        "Converts text to speech using the traditional TTS service.",
      icon: <HiMiniSpeakerWave />,
    },
    {
      name: "Visualization",
      description:
        "Generates visualizations using the LIDO library.",
      icon: <BsGraphUp />,
    },
    {
      name: "Swagger API",
      description:
        "Manages issues and integrates with DayOne using the Swagger API.",
      icon: <SiSwagger />,
    },
    {
      name: "OpenAI Chat",
      description:
        "Provides standard chat responses using OpenAI's language model.",
      icon: <SiOpenai />,
    },
    {
      name: "Web Documents",
      description:
        "Embeds documents directly from websites for easy access and viewing.",
      icon: <SlGlobe />,
    },
    {
      name: "Drive Documents",
      description:
        "Integrates Google Drive documents for seamless access and sharing within the chat platform.",
      icon: <MdAddToDrive />,
    },
  ];

  return (
    <div className="m-5">
      <h1 className="text-xl font-semibold text-gray/950">
        Choose any plugins to boost your bot
      </h1>
      <div className="mt-6 flex gap-6 flex-wrap">
        {plugins.map((item) => (
          <div className="w-64 h-40 p-4 bg-white rounded-lg">
            <div className="flex justify-between items-center">
              <div className="plugins-icon">{item.icon}</div>
              <button className="btn-2">Install</button>
            </div>
            <div className="mt-2">
              <h2 className="text-base text-gray/950 font-semibold">
                {item.name}
              </h2>
              <p className="text-gray/500 text-sm">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plugins;
