import {
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Login from "./page/login";
import Singup from "./page/signup";
import MyForm from "./page/form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { Loader, notification } from "./helper";
import Pricing from "./page/pricing";
import MainLayout from "./components/layout";
import MyBots from "./page/my-bots";
import ApiKey from "./page/api-key";
import Profile from "./page/profile";
import instance from "./api";
import WhatsAppNumbers from "./page/whatsapp-numbers";
import Callback from "./page/callback";
import { useLogto } from "@logto/react";
import Teams from "./page/teams";
import Templates from "./page/templates";
import Join from "./page/join";
import Plugins from "./page/plugins";
import BotLayout from "./page/bot/components/layout";
import RequireAuth from "./RequireAuth";
import { useSubscription } from "./context/SubscriptionContext";
import OrderConfirm from "./page/order-confirm";
import Inbox from "./page/inbox";

function App() {
  const { isAuthenticated, fetchUserInfo } = useLogto();
  const { orgDetails, user, loading, setLoading } = useSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.organization_data.length > 0) {
      } else {
        if (!window.location.pathname.includes("/join")) {
          navigate("/form");
        }
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (orgDetails) {
      if (orgDetails.free_trial === "active") {
        instance
          .post(`/razorpay/check-free-trial/${orgDetails.org_id}`)
          .then((res) => {
            // console.log(res, "This is free_trial")
          })
          .catch((err) => {
            console.log(err);
            notification.error(err.response.data.error);
          });
      }
    }
  }, [orgDetails]);

  return (
    <Loader spin={loading}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Singup />} />
          <Route path="/callback" element={<Callback setLoading={setLoading} />} />
          <Route path="/form" element={<MyForm user={user} />} />

          <Route element={<RequireAuth />}>
            <Route path="/join/:inviteId" element={<Join user={user} />} />

            <Route path="/" element={<MainLayout user={user} />} >
              <Route index element={<MyBots user={user} setLoading={setLoading}/>} />
              <Route index path="pricing" element={<Pricing user={user} setLoading={setLoading} />} />
              <Route index path="settings" element={<ApiKey />} />
              {user?.roles?.includes("superadmin") && <Route index path="plugins" element={<Plugins />} />}
              {/* <Route index path="playground" element={<Playground user={user} />} /> */}
              <Route index path="profile" element={<Profile user={user} />} />
              {user?.roles?.includes("superadmin") && <Route index path="whatsapp-numbers" element={<WhatsAppNumbers user={user} />} />}
              <Route index path="teams" element={<Teams user={user} setLoading={setLoading} />} />
              <Route index path="templates" element={<Templates user={user} setLoading={setLoading} />} />
              <Route index path="order-confirm" element={<OrderConfirm user={user} setLoading={setLoading} />} />
              <Route index path="inbox" element={<Inbox user={user} setLoading={setLoading} />} />
            </Route>
          </Route>

          <Route path="/bot" >
            <Route index path="create" element={<BotLayout user={user} setLoading={setLoading} /> }/> 
            <Route index path="edit/:botId" element={<BotLayout user={user} setLoading={setLoading} /> }/> 
            <Route index path="template/:template" element={<BotLayout user={user} setLoading={setLoading} /> }/> 
          </Route>
        </Routes>

      <ToastContainer />
    </Loader>
  );
}

export default App;
