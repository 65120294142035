import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useSubscription } from "./SubscriptionContext";
import { io } from "socket.io-client";
import instance from "../api";
import axios from "axios";
import { nanoid } from "nanoid";

const InboxContext = createContext();

export const useInox = () => useContext(InboxContext);

export const InboxProvider = ({ children }) => {
  const [openDetails, setOpenDetails] = useState(true);
  const { currentOrg, user, setLoading } = useSubscription();
  const [history, setHistory] = useState([]);
  const [chats, setChats] = useState([]);
  const [conversationId, setConversationId] = useState();
  const conversationIdRef = useRef(conversationId);
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState(currentOrg);
  const [selectedOption, setSelectedOption] = useState("all");
  const [newMessages, setNewMessages] = useState([]);
  const [userList, setUserList] = useState([]);
  const [botList, setBotList] = useState([]);

  useEffect(() => {
    if (currentOrg) {
      if (selectedOption === "all") {
        getBotHistory();
      } else getBotChats();
    }
    setConversationId();
  }, [currentOrg, selectedOption]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL); // Replace with your server URL
    setSocket(newSocket);

    // Join the room when the socket connects
    newSocket.on("connect", () => {
      newSocket.emit("joinRoom", currentOrg);
    });

    // Listen for messages from the server
    newSocket.on("message", (data) => {
      // alert(`Message received: ${data}`); // Access the content of the message
      setNewMessages((prevMessages) => {
        if (prevMessages.includes(data)) {
          return prevMessages;
        }
        return [...prevMessages, data];
      });

      if (selectedOption === "all") {
        getBotHistory();
      } else getBotChats();
      if (data === conversationIdRef.current)
        getConversation(conversationIdRef.current);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [currentOrg]);

  useEffect(() => {
    conversationIdRef.current = conversationId; // Sync the ref with the state
  }, [conversationId]);

  const sendMessage = (message) => {
    if (socket) {
      console.log(`Sending message: ${message} to room: ${currentOrg}`);
      socket.emit("message", { room: currentOrg, message }); // Include room and message content
    }
  };

  const getBotHistory = async () => {
    // setLoading(true);
    instance
      .post(`/bot/org-history/${currentOrg}`)
      .then((res) => {
        console.log(res, "This is org history");
        setHistory(res?.data);

        const uniqueUsers = Array.from(
          new Map(
            res?.data.map((user) => [
              user.user_id,
              { email: user.email, id: user.user_id },
            ])
          ).values()
        );
        setUserList(uniqueUsers);
        const uniqueBots = Array.from(
          new Map(
            res?.data.map((bot) => [
              bot.bot_id,
              { name: bot.bot_name, id: bot.bot_id },
            ])
          ).values()
        );
        setBotList(uniqueBots);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        // setLoading(false);
      });
  };

  const getBotChats = async () => {
    // setLoading(true);
    instance
      .post(`/bot/history/${selectedOption}`)
      .then((res) => {
        console.log(res, "This is bot history");
        setHistory(res?.data);

        const uniqueUsers = Array.from(
          new Map(
            res?.data.map((user) => [
              user.user_id,
              { email: user.email, id: user.user_id },
            ])
          ).values()
        );
        setUserList(uniqueUsers);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        // setLoading(false);
      });
  };

  const getConversation = async (currentConversationId) => {
    await axios
      .get(
        `${process.env.REACT_APP_CHAT_BE_URL}/api/chat/get_conversation/${currentConversationId}`
      )
      .then((res) => {
        console.log(res, "This is conversation");
        // Update the session part of the chats state
        setChats((prevChats) => ({
          ...prevChats, // Spread the existing chats
          session: res?.data?.conversation?.session, // Update the session with new data
          manual: res?.data?.conversation?.manual, // Update the session with new data
        }));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const saveChat = async (e) => {
    e.preventDefault();
    // setLoading(true);

    try {
      const data = {
        session: {
          messages: [
            ...chats.session,
            {
              id: nanoid(7),
              content: message,
              role: "system",
              createdAt: new Date(),
              sender: user.name,
            },
          ],
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CHAT_BE_URL}/api/chat/save_chat/${chats.user_id}/${chats.bot_id}`,
        data,
        {
          params: { conversation_id: conversationId },
        }
      );

      console.log(response, "This is save chat");
      sendMessage(conversationId);
      if (selectedOption === "all") {
        await getBotHistory();
      } else await getBotChats();
      setMessage("");
    } catch (error) {
      console.log(error, "error");
    } finally {
      // setLoading(false);
    }
  };

  const switchManual = async (status) => {
    await axios
      .post(
        `${process.env.REACT_APP_CHAT_BE_URL}/api/chat/update_convo_manual/${conversationId}/${status}`
      )
      .then((res) => {
        console.log(res, "This is manual update");
        getConversation(conversationIdRef.current);
        sendMessage(conversationId);
        if (selectedOption === "all") {
          getBotHistory();
        } else getBotChats();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <InboxContext.Provider
      value={
        {
          history,
          conversationId, setConversationId,
          chats, setChats,
          message, setMessage,
          selectedOption, setSelectedOption,
          newMessages, setNewMessages,
          userList, botList,
          openDetails, setOpenDetails,
          saveChat: (e) => saveChat(e),
          switchManual: (e) => switchManual(e),
        }
      }
    >
      {children}
    </InboxContext.Provider>
  );
};
