import React, { useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import { Dropdown } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaPlus, FaRegCreditCard } from "react-icons/fa6";
import { GoSignOut } from "react-icons/go";
import { notification } from "../../../../helper";
import instance from "../../../../api";
import { BsPersonLinesFill } from "react-icons/bs";
import { useLogto } from "@logto/react";
import { LuEye } from "react-icons/lu";
import { VscJson } from "react-icons/vsc";
import { IoChevronBack } from "react-icons/io5";
import { useSubscription } from "../../../../context/SubscriptionContext";

const BotHeader = ({ user, current, setKey, code }) => {
  const [title, setTitle] = useState("");
  const location = useLocation();
  const { botId } = useParams();
  const navigate = useNavigate();
  const parsedCode = JSON.parse(code);
  const { orgDetails } = useSubscription();

  const { signOut, clearAllTokens } = useLogto();

  // useEffect(() => {
  //   let path = location.pathname;
  //   if (path === "/bot/create"){
  //     setTitle("Create Bot")
  //     // setKey('code')
  //   }
  //   else if (path.includes("/bot/edit")){
  //     setTitle("Edit Bot")
  //     // setKey('code')
  //   }
  //   else setTitle("");
  // }, [location.pathname]);

  const generateBilling = () => {
    instance
      .post("/billing", { org_id: localStorage.getItem("org") })
      .then((res) => {
        // console.log(res, "THis is billing")
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const items = [
    {
      key: 3,
      icon: <BsPersonLinesFill className="text-xl" />,
      label: <h2 className="">My Profile</h2>,
      onClick: () => navigate("/profile")
    },
    {
      key: 2,
      icon: <FaRegCreditCard className="text-xl" />,
      label: (
        <button>Billing</button>
      ),
      onClick: () => navigate("/pricing")
    },
    {
      key: 4,
      icon: <GoSignOut className="text-xl text-error/500" />,
      label: <h2 className="text-error/500">Sign Out</h2>,
      onClick: () => {
        // navigate("/login");
        localStorage.removeItem("org");
        clearAllTokens();
        signOut(process.env.REACT_APP_CALLBACK_URL);
      },
    },
  ];

  return (
    <Header
      style={{
        backgroundColor: "white",
        borderBottom: "1px solid #D6D8E1",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
      }}
    >
      <div className="flex justify-between items-center">
        <div>
          {location.pathname.includes("/bot/create") ? <button className="text-xl font-semibold capitalize flex items-center gap-2" onClick={() => navigate("/")}>
            <IoChevronBack className="text-xl" />
            {current}
          </button> :
          <h1 className="text-xl font-semibold capitalize flex items-center gap-2">{parsedCode.name}</h1>}
        </div>
        <div className="flex items-center gap-5">
          <button className="btn-2 py-4 border-blue/500">
            {orgDetails?.name}
          </button>
          {!location.pathname.includes("/bot/create") && <button className={`btn-2 ${current === 'code' ? "border-blue/500 text-blue/500 bg-blue/50" : "text-gray/950 "}`} onClick={() => setKey('code')}>
            <VscJson className="text-md" />
            Json
          </button>}
            {!location.pathname.includes("/bot/create") && <a className="text-gray/950 btn-2" href={`${process.env.REACT_APP_CHAT_FE_URL}/${botId}`} target="_blank">
                <LuEye className="text-md" /> 
                Preview
            </a>}
          <Dropdown menu={{ items }}>
            <img className="size-10 rounded-full hover:border-2 hover:border-blue/500 cursor-pointer" src={user?.picture ? user?.picture : `https://ui-avatars.com/api/?name=${user?.name}&bold=true&color=fff&background=cdbbfa`} alt="" />
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default BotHeader;