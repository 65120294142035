import instance from "../api";
import { ApiEndpoints } from "../constants";

export const botService = {
  getBotModels,
};

async function getBotModels() {
  try {
    const response = await instance.get(ApiEndpoints.GET_BOT_MODELS);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch bot models:", error);
    throw error;
  }
}
