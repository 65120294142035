import React, { useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import { CgProfile } from "react-icons/cg";
import { Dropdown, Progress } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown, FaBolt, FaPlus, FaRegCreditCard } from "react-icons/fa6";
import { GoSignOut } from "react-icons/go";
import { notification, returnPlanName } from "../../helper";
import instance from "../../api";
import { TbExternalLink } from "react-icons/tb";
import { BsPersonLinesFill } from "react-icons/bs";
import { useLogto } from "@logto/react";
import { IoChevronBack } from "react-icons/io5";
import { useSubscription } from "../../context/SubscriptionContext";
import { MdDataUsage } from "react-icons/md";

const MainHeader = ({ user }) => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { orgDetails, allBots, members, currentOrg, setCurrentOrg } = useSubscription();

  const { signOut, clearAllTokens } = useLogto();

  useEffect(() => {
    let path = location.pathname;
     if (path === "/") setTitle("My Bots");
    else if (path === "/settings") setTitle("Settings");
    else if (path === "/whatsapp-numbers") setTitle("WhatsApp Numbers");
    else if (path === "/teams") setTitle("Teams");
    else if (path === "/plugins") setTitle("Plugins Manager");
    else if (path === "/playground") setTitle("Playground");
    else if (path === "/templates") setTitle("Templates");
    else if (path === "/pricing") setTitle("Pricing");
    else if (path === "/inbox") setTitle("Inbox");
    else setTitle("");
  }, [location.pathname]);

  const generateBilling = () => {
    instance
      .post("/billing", { org_id: localStorage.getItem("org") })
      .then((res) => {
        // console.log(res, "THis is billing")
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const items = [
    {
      key: 3,
      icon: <BsPersonLinesFill className="text-xl" />,
      label: <h2 className="">My Profile</h2>,
      onClick: () => navigate("/profile")
    },
    {
      key: 2,
      icon: <FaRegCreditCard className="text-xl" />,
      label: (
        <button>Billing</button>
      ),
      onClick: () => navigate("/pricing")
    },
    {
      key: 4,
      icon: <GoSignOut className="text-xl text-error/500" />,
      label: <h2 className="text-error/500">Sign Out</h2>,
      onClick: () => {
        // navigate("/login");
        localStorage.removeItem("org");
        clearAllTokens();
        signOut(process.env.REACT_APP_CALLBACK_URL);
      },
    },
  ];

  const content = (
    <div>
      <p>Team: {members?.length - 1} / {orgDetails?.team}</p>
      <p>Bot: {allBots?.length} / {orgDetails?.bot}</p>
    </div>
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Header
      style={{
        backgroundColor: "white",
        borderBottom: "1px solid #D6D8E1",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
      }}
    >
      <div className="flex justify-between items-center">
        <div>
          {location.pathname.includes("/templates") || location.pathname.includes("/pricing") ? <button className="text-xl font-semibold capitalize flex items-center gap-2" onClick={() => navigate("/")}>
            <IoChevronBack className="text-xl" />
            {title}
          </button> :
          <h1 className="text-xl font-semibold capitalize flex items-center gap-2">{title}</h1>}
        </div>
        <div className="flex items-center gap-5">
          {/* <button className="font-semibold flex items-center gap-2 btn gradient" onClick={() => navigate("/bot/create")}>
            <FaPlus className="" />
            Create Bot
          </button> */}
          {/* <Popover content={orgs} title="Organizations" trigger="hover">
            <button className="btn-2 py-4 border-blue/500">
              {orgDetails?.name}
            </button>
          </Popover> */}
          {orgDetails?.status === 'active' && <div className="flex items-center gap-2">
            <Progress type="circle" size={38} showInfo={false} trailColor="#D6D8E1" strokeColor="#7F51F2" percent={((allBots?.length)  / orgDetails?.bot) * 100} />
            <div>
              <h3 className="text-gray/950 font-semibold text-sm">{orgDetails?.plan_name}</h3>
              <p className="text-gray/400 text-xs">{allBots?.length}/{orgDetails?.bot} bots used</p>
            </div>
          </div>}
          {orgDetails?.free_trial === 'pending' && <p className="text-sm text-gray/400 font-normal">
            Features are just a click away! <button className="underline" onClick={() => navigate('/pricing')}>Activate</button> your free trial to get started.
          </p>}
          {orgDetails?.free_trial === 'active' && <p className="text-sm text-gray/400 font-normal">
            Free Trial ends on {formatDate(orgDetails?.free_trial_expires_in)}
          </p>}
          {orgDetails?.free_trial === 'active' &&
             <button className="btn py-2 flex items-center gap-2" onClick={() => navigate("/pricing")}>
              <FaBolt className="text-lg" />
              Upgrade
              <span className="text-sm text-blue/100 font-normal">(Free Plan)</span>
            </button>}
          {/* {orgDetails?.status === 'active' && <Popover content={content} title="Usage" trigger="hover">
             <button className="btn-2 py-4 border-blue/500">
              {orgDetails?.plan_name}
            </button>
          </Popover>} */}
          <Dropdown menu={{ items }}>
            <img className="size-10 rounded-full hover:border-2 hover:border-blue/500 cursor-pointer" src={user?.picture ? user?.picture : `https://ui-avatars.com/api/?name=${user?.name}&bold=true&color=fff&background=cdbbfa`} alt="" />
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default MainHeader;
