import React, { createContext, useContext, useEffect, useState } from 'react';
import instance from '../api';
import { notification } from '../helper';
import { useNavigate } from 'react-router-dom';
import { useLogto } from '@logto/react';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const { isAuthenticated, fetchUserInfo, signOut } = useLogto();
  const [orgDetails, setOrgDetails] = useState(null);
  const [currentOrg, setCurrentOrg] = useState();
  const [allBots, setAllBots] = useState([]);
  const [members, setMembers] = useState([]);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        getTokens();
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentOrg) {
      getOrgById(currentOrg);
    }
  }, [currentOrg]);

  // useEffect(() => {
  //   if (orgDetails?.free_trial !== 'active' || orgDetails?.status !== 'active') {
  //     navigate("/pricing");
  //   }
  // }, [orgDetails]);

  const getTokens = async () => {
    setLoading(true);

    const user = await fetchUserInfo();

    if(!user) {
      signOut(process.env.REACT_APP_CALLBACK_URL)
    }

    setUser(user);
    // localStorage.setItem("org", user?.organizations[0]);
    // setCurrentOrg(user?.organizations[0]);

    const storedOrg = localStorage.getItem("org");

    if (storedOrg && user?.organizations.includes(storedOrg)) {
      setCurrentOrg(storedOrg);
    } else {
      const firstOrg = user?.organizations[0];
      localStorage.setItem("org", firstOrg);
      setCurrentOrg(firstOrg);
    }

    // getOrgById(user?.organizations[0]);

    setLoading(false);
  };
  
  const getOrgById = (id) => {
    instance
      .get(`/organizations/${id}`)
      .then((res) => {
        setOrgDetails(res.data.data);
        // console.log(res, "This is orgDetails");
        if (res?.data?.data?.razorpay?.razorpay_subscription_id && res?.data?.data?.free_trial !== 'active') {
          getSubscription(res?.data?.data?.razorpay?.razorpay_subscription_id);
          getOrgTokenUsage();
        }
        if (res?.data?.data?.free_trial === 'active') {
          getSubscription("free");
          getOrgTokenUsage();
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const getSubscription = (id) => {
    instance
      .get(`/razorpay/subscription/${id}`)
      .then((res) => {
        // console.log(res, "This is subscription");
        setOrgDetails((prevOrgDetails) => ({
          ...prevOrgDetails,
          plan_id: res?.data?.data?.plan_id,
          plan_name: res?.data?.data?.plan?.item?.name,
          plan_description: res?.data?.data?.plan?.item?.description,
          plan_amount: res?.data?.data?.plan?.item?.amount,
          next_charge_at: res?.data?.data?.charge_at,
          features: res?.data?.data?.plan?.details?.features, // active
          // Change this value for local testing
          status: res?.data?.data?.status, // active
          team: res?.data?.data?.plan?.details?.team, // number
          bot: res?.data?.data?.plan?.details?.bot, // number
          token: res?.data?.data?.plan?.details?.token, // number
          llama_model: res?.data?.data?.plan?.details?.llama_model, // boolean
          confluence_support: res?.data?.data?.plan?.details?.confluence_support, // boolean
          whatsapp_support: res?.data?.data?.plan?.details?.whatsapp_support, // boolean
          file_upload_limit: res?.data?.data?.plan?.details?.file_upload_limit, // boolean
          sites_to_scrape: res?.data?.data?.plan?.details?.sites_to_scrape, // number
        }));
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const getOrgTokenUsage = () => {
    instance
      .post(`/organizations/token-usage`, { org_id: currentOrg })
      .then((res) => {
        // console.log(res, "This is token usage");
        setOrgDetails((prevOrgDetails) => ({
          ...prevOrgDetails,
          used_token: res?.data?.data?.used_token,
        }));
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
      });
  };

  const canInviteMember = (navigate) => {
    if (orgDetails && orgDetails.status !== "active" && orgDetails.free_trial !== 'active') {
      navigate("/pricing");
      notification.error("You don't have a valid plan");
      return false;
    }
    if (orgDetails && members.length - 1 >= orgDetails.team) {
      navigate("/pricing");
      notification.error("You have reached the maximum number of team members");
      return false;
    }
    return true;
  };

  const canCreateBot = (navigate) => {
    if (orgDetails && orgDetails.status !== "active" && orgDetails.free_trial !== 'active') {
      navigate("/pricing");
      notification.error("You don't have a valid plan");
      return false;
    }
    if (orgDetails && allBots.length >= orgDetails.bot) {
      navigate("/pricing");
      notification.error("You have reached the maximum number of bots");
      return false;
    }
    return true;
  };

  return (
    <SubscriptionContext.Provider
      value={{
        orgDetails,
        getOrgById,
        canInviteMember: () => canInviteMember(navigate),
        canCreateBot: () => canCreateBot(navigate),
        allBots,
        setAllBots,
        members,
        setMembers,
        currentOrg,
        setCurrentOrg,
        user,
        loading,
        setLoading,
        getTokens
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};