import React from "react";
import { FaXmark } from "react-icons/fa6";

const Details = ({ chats, setOpenDetails }) => {

  return (
    <div className="w-1/5 border-l border-gray/200 bg-white p-3 space-y-3">
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-semibold text-gray/950">Basic Details</h1>
        <button onClick={() => setOpenDetails(false)}>
            <FaXmark className="text-xl" />
        </button>
      </div>
      <div>
        <h2 className="text-sm text-gray/400">Total words answered by bot</h2>
        <h2 className="text-sm text-gray/950 font-semibold">{chats?.token_count}</h2>
      </div>
    </div>
  );
};

export default Details;
