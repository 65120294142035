import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin, Steps, Tooltip } from "antd";
import { useRef, useState } from "react";
import { Bounce, Slide, toast } from "react-toastify";
import gif from "../assets/box-loader.gif"

export const notification = {
  success: (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      newestOnTop: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: "dark",
      transition: Slide,
    });
  },
  error: (message) => {
    const errorMessage = message || "An error occurred";
    toast.error(errorMessage, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      newestOnTop: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: "dark",
      transition: Slide,
    });
  },
  warning: (message) => {
    toast.warning(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  },
  info: (message) => {
    toast.info(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  },
};


export const Loader = ({ spin, children }) => {
  return(
    <Spin 
      spinning={spin}
      indicator={<img src={gif} alt="box-loader" style={{ width: "45px", height: "45px" }} />}
      className="min-h-screen"
    >
      {children}
    </Spin>
  )
}


export const returnPlanName = (plan) => {
  switch (plan) {
    case 'prod_PwnXC6iHfMPeFz':
        return 'Free Plan'
    case 'prod_PqVoSctEKZ1DFv':
        return 'Test Plan 1'
    case 'prod_PqWMsGBi3mgvog':
        return 'Test Plan 2'
    case 'prod_PqWOwBCks0Jb1t':
        return 'Test Plan 3'
    default:
        return 'Unknown'
  }
}


export const CopyToClipboard = ({ textToCopy, children }) => {
  const [tooltipTitle, setTooltipTitle] = useState('Copy');
  const tooltipRef = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      if (tooltipRef.current) {
        setTooltipTitle('Copied');
        setTimeout(() => {
          setTooltipTitle('Copy');
        }, 2000);
      }
    });
  };

  return (
    <button onClick={copyToClipboard}>
      <Tooltip title={tooltipTitle} ref={tooltipRef}>
        {children}
      </Tooltip>
    </button>
  );
};


export const BoxLoader = ({ current, loading }) => {
  return(
    <Modal
      title={<h1 className="text-xl font-bold text-gray/950">Almost there!</h1>}
      centered
      open={loading}
      closable={false}
      width={380}
      footer={null}
    >
      <div className="h-[200px]">
      <Steps
        current={current}
        className="mt-4 h-full"
        direction="vertical"
        items={[
          {
            title: 'Saving your changes',
            icon: current === 0 ? <LoadingOutlined className="text-blue/500" /> : null,
          },
          {
            title: 'Updating knowledge sources',
            icon: current === 1 ? <LoadingOutlined className="text-blue/500" /> : null,
          },
        ]}
      />
      </div>
    </Modal>
  )
}

export function convertToBytes(size) {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 ** 2,
    GB: 1024 ** 3,
    TB: 1024 ** 4
  };

  const regex = /^(\d+)(B|KB|MB|GB|TB)$/;
  const match = size.match(regex);

  if (!match) {
    throw new Error("Invalid size format");
  }

  const value = parseInt(match[1], 10);
  const unit = match[2];

  return value * units[unit];
}

export function convertFromBytes(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;

  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  return `${bytes.toFixed(2)}${units[unitIndex]}`;
}