import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../../api";
import { notification } from "../../helper";
import { FcGoogle } from "react-icons/fc";
import { Form, Input } from "antd";
import { useLogto } from "@logto/react";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const { signIn } = useLogto();
  useEffect(() => {
    signIn(`${process.env.REACT_APP_CALLBACK_URL}/callback`);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      console.log("Token found:", token);
      // setToken(token);
      localStorage.setItem("token", token);
      notification.success("Logged in successfully!");
      navigate("/bots");
    }
  }, [location, navigate]);

  const login = (data) => {
    instance
      .post(`/auth/login`, data)
      .then((res) => {
        console.log(res);
        if (!res.data.data.domain) {
          navigate("/form", { state: { accountId: res.data.data._id } });
        } 
        //Verify logic
        else if(!res.data.data.isVerified){
          navigate("/verify-email", { state: { accountId: res.data.data._id } });
        }
        else {
          // setToken(res.data.data.access_token);
          localStorage.setItem("token", res.data.data.access_token);
          notification.success("Logged in successfully!");
          navigate("/bots");
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
      });
  };

  return null;

  return (
    <div className="auth-wrapper">
      <Form
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        onFinish={(val) => login(val)}
        className="auth-content"
      >
        <h1 className="text-center text-3xl font-semibold">
          Welcome to Avatar
        </h1>
        <Form.Item
          label={<h2>Email Address</h2>}
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: "Please input your email address!",
            },
          ]}
        >
          <Input
            className="input min-w-full"
            placeholder="johndoe@example.com"
          />
        </Form.Item>
        <Form.Item
          label={<h2>Password</h2>}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            className="input min-w-full"
            placeholder="Password"
          />
        </Form.Item>
        <div className="text-xs flex justify-between items-center">
          <h4></h4>
          <a href="/forgot-password">Forgot Password?</a>
        </div>
        <button className="btn w-full">Log in</button>
        <h2 className="text-center">
          Don’t have an account?{" "}
          <button className="underline" onClick={() => navigate("/signup")}>
            Sign up
          </button>
        </h2>
        <h2 className="w-full text-center border-b leading-[0.1em]">
          <span className="bg-white px-6">Or</span>
        </h2>
        <button
          type="button"
          className="btn w-full bg-white text-gray/950 border flex justify-center items-center gap-2"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`)
          }
        >
          <FcGoogle className="text-xl" />
          Continue with Google
        </button>
      </Form>
    </div>
  );
};

export default Login;
