import { useLogto } from '@logto/react';
import { useCallback } from 'react';
import { useSubscription } from '../context/SubscriptionContext';

const useAccessToken = () => {
  const { getAccessToken } = useLogto();
  const { currentOrg } = useSubscription();

  const fetchAccessToken = useCallback(async (id) => {
    const token = await getAccessToken(process.env.REACT_APP_BASE_URL, id ? id : currentOrg);
    return token;
  }, [getAccessToken]);

  return fetchAccessToken;
};

export default useAccessToken;