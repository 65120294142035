import React, { useEffect, useState } from "react";
import empty from "../../assets/Inbox_empty.png";
import { useNavigate } from "react-router-dom";
import instance from "../../api";
import { BoxLoader, notification } from "../../helper";
import { formatDistanceToNow } from "date-fns";
import { LoadingOutlined } from "@ant-design/icons";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { Modal, Tooltip } from "antd";
import { LuPencil } from "react-icons/lu";
import { useLogto } from "@logto/react";
import useAccessToken from "../../hooks/useAccessToken";
import scracth from '../../assets/scracth.png'
import json from '../../assets/json.png'
import template from '../../assets/template.png'
import scracthIcon from '../../assets/scratch-icon.png'
import jsonIcon from '../../assets/json-icon.png'
import templateIcon from '../../assets/template-icon.png'
import { useSubscription } from "../../context/SubscriptionContext";
import gif from "../../assets/box-loader.gif";

const MyBots = ({ user }) => {
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [botToDelete, setBotToDelete] = useState();
  const navigate = useNavigate(); 
  const { isAuthenticated } = useLogto();
  const fetchAccessToken = useAccessToken();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const { currentOrg, allBots, setAllBots } = useSubscription();

  useEffect(() => {
    if (isAuthenticated && user && currentOrg) {
      getBots();
    }
  }, [isAuthenticated, user, currentOrg]);

  const [wordCounts, setWordCounts] = useState({});

  const getBots = async () => {
    setIsLoading(true);
    const token = await fetchAccessToken(currentOrg);
    instance.get(`/bot/org/${currentOrg}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(async (res) => {
        // console.log(res.data.data, "This is all bots");
        if (res.data.data.length === 0) {
          setNoData(true);
          setAllBots([]);
        } else {
          setAllBots(res.data.data);
          setNoData(false);
        }
        setIsLoading(false);
  
        // Fetch word counts for each bot
        const counts = {};
        // for (const bot of res.data.data) {
        //   counts[bot._id] = await getBotChat(bot._id);
        // }
        // setWordCounts(counts);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err?.response?.data?.error);
        setIsLoading(false);
      });
  };

  const getBotChat = async (id) => {
    try {
      const res = await instance.get(`${process.env.REACT_APP_CHAT_BE_URL}/api/chat/get_chat/${id}`);
      // console.log(res, "This is chat history");
  
      // Filter messages where role is 'assistant' and count the words in their content
      const wordCount = res.data.session.messages
        .filter(message => message.role === 'assistant')
        .reduce((acc, message) => acc + message.content.split(' ').length, 0);
  
      return wordCount;
    } catch (err) {
      console.log(err);
      return 0; // Return 0 or any default value in case of an error
    }
  };

  const deleteBot = () => {
    instance
      .delete(`/bot/delete/${botToDelete._id}`)
      .then((res) => {
        console.log(res);
        getBots();
        notification.success("Bot deleted successfully!");
        setDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
        notification.error(err.response.data.error);
        setDeleteModal(false);
      });
  };

  const handleGenerate = () => {
    setLoading(true);
    setCurrent(1);
    instance
      .post(
        `/api/generate/org`,
        { orgId: currentOrg },
      )
      .then((res) => {
        console.log(res, "THis is generate org");
        setLoading(false);
        notification.success("Bot generated successfully!");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        notification.error(err?.response?.data?.message?.error);
      });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <img src={gif} alt="box-loader" style={{ width: "45px", height: "45px" }} />
      </div>
    );
  }

  if (noData) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center gap-5 text-center text-lg text-gray/950">
        <img src={empty} alt="" />
        <h2 className="grotesk text-3xl font-medium">
          Let’s create your first Bot
        </h2>
        <h2 className="text-gray/500 text-base">
        Create your automated assistant to manage your business. 
        <br />  
        Choose from any method you would like to start with.
        </h2>
        <button className="w-72 bg-white p-2.5 rounded-xl flex items-center gap-4 border border-transparent hover:border-blue/500 hover:bg-blue/50" onClick={() => navigate("/bot/create")}>
          <img src={scracthIcon} alt="" className="p-2 bg-blue/50 rounded-md" />
          <h2 className="font-semibold text-">Start from scratch</h2>
        </button>
        <button className="w-72 bg-white p-2.5 rounded-xl flex items-center gap-4 border border-transparent hover:border-[#FF725E] hover:bg-[#FFEEEB]" onClick={() => navigate("/bot/create", { state: { json: true } })}>
          <img src={jsonIcon} alt="" className="p-2 bg-[#FFEEEB] rounded-md" />
          <h2 className="font-semibold text-">Start with code</h2>
        </button>
        <button className="w-72 bg-white p-2.5 rounded-xl flex items-center gap-4 border border-transparent hover:border-[#3EB57B] hover:bg-[#E7F6EF]" onClick={() => navigate("/templates")}>
          <img src={templateIcon} alt="" className="p-2 bg-[#E7F6EF] rounded-md" />
          <h2 className="font-semibold text-">Start with a template</h2>
        </button>
      </div>
    );
  }

  return (
    <div>

        {/* <div className="bg-white p-2 px-5 text-base">
          <button className="p-1 px-3 bg-gray/100 rounded-md">All Bots</button>
        </div> */}

        <div className="m-5">
          <h2 className="font-medium text-base mb-3">Start creating</h2>
          <div className="flex gap-5">
            <div className="scracthGradient bg-white rounded-xl p-4 w-56 h-36 hover:drop-shadow-md cursor-pointer shrink-0 flex flex-col justify-center items-center gap-3 text-gray/950" onClick={() => navigate("/bot/create")}>
              <img className="w-16" src={scracth} alt="" />
              <h2 className="font-medium text-sm">Start from scratch</h2>
            </div>
            <div className="jsonGradient bg-white rounded-xl p-4 w-56 h-36 hover:drop-shadow-md cursor-pointer shrink-0 flex flex-col justify-center items-center gap-3 text-gray/950"  onClick={() => navigate("/bot/create", { state: { json: true } })}>
              <img className="w-14" src={json} alt="" />
              <h2 className="font-medium text-sm">Start with code</h2>
            </div>
            <div className="templateGradient bg-white rounded-xl p-4 w-56 h-36 hover:drop-shadow-md cursor-pointer shrink-0 flex flex-col justify-center items-center gap-3 text-gray/950" onClick={() => navigate("/templates")}>
              <img className="w-14" src={template} alt="" />
              <h2 className="font-medium text-sm">Start with a template</h2>
            </div>
          </div>

          <div className="mt-5 mb-3 flex justify-between items-center">
            <h2 className="font-medium text-base">Your Bots</h2>
            {/* <button className="btn" onClick={handleGenerate}>
              Generate All
            </button> */}
          </div>
          <div className="flex flex-wrap gap-5">
            {allBots.map((item) => (
              <div
                key={item._id}
                className="bg-white rounded-xl p-4 w-56 drop-shadow-md cursor-pointer shrink-0 flex flex-col justify-between"
                onClick={() => {
                  navigate(`/bot/edit/${item._id}`)
                }}
              >
                <div>
                  <div className="flex justify-between items-center">
                    <Tooltip placement="topLeft" title={item.name} arrow={false}>
                      <h2 className="font-medium text-base truncate">{item.name}</h2>
                    </Tooltip>
                    {item.is_public && <span className="shrink-0 text-success/500 font-medium text-xs bg-success/50 px-2 py-1 rounded-full">
                      Public
                    </span>}
                  </div>
                  <span className="text-gray/500 text-xs block">
                    Updated{" "}
                    {formatDistanceToNow(new Date(item.createdAt), {
                      addSuffix: true,
                    })}
                  </span>
                  <span className="text-gray/500 text-xs">
                    {item?.created_by}
                  </span>
                  {/* <span className="text-gray/500 text-xs block">
                    Count:{" "}
                    {wordCounts[item._id] || 0}
                  </span> */}
                </div>
                <div className="mt-3 flex justify-start items-center gap-3">
                  <a
                    className="btn-2 rounded-full size-8 p-0 flex justify-center items-center hover:text-blue/500"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    href={`${process.env.REACT_APP_CHAT_FE_URL}/${item._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiEye className="text-lg" />
                  </a>
                  <button
                    className="btn-2 rounded-full size-8 p-0 flex justify-center items-center hover:text-blue/500"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/bot/edit/${item._id}`)
                    }}
                  >
                    <LuPencil className="text-lg" />
                  </button>
                  <button
                    className="btn-2 rounded-full size-8 p-0 flex justify-center items-center text-error/500 hover:text-error/500 hover:border-error/500"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBotToDelete(item);
                      setDeleteModal(true);
                    }}
                  >
                    <FaRegTrashAlt className="text-base" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

      <Modal
        title={`Delete ${botToDelete?.name}`}
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer=""
      >
        <p>Are you sure you want to delete this bot?</p>
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="btn bg-transparent border border-blue/500 text-blue/500"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn bg-error/500" onClick={() => deleteBot()}>
            Delete
          </button>
        </div>
      </Modal>

      <BoxLoader loading={loading} current={current} />
    </div>
  );
};

export default MyBots;
